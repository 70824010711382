"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfChatbot = instanceOfChatbot;
exports.ChatbotFromJSON = ChatbotFromJSON;
exports.ChatbotFromJSONTyped = ChatbotFromJSONTyped;
exports.ChatbotToJSON = ChatbotToJSON;
var ChatbotItem_1 = require("./ChatbotItem");
/**
 * Check if a given object implements the Chatbot interface.
 */
function instanceOfChatbot(value) {
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('items' in value) || value['items'] === undefined)
        return false;
    if (!('medical_record_id' in value) || value['medical_record_id'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    return true;
}
function ChatbotFromJSON(json) {
    return ChatbotFromJSONTyped(json, false);
}
function ChatbotFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'created_at': json['created_at'],
        'id': json['id'],
        'items': (json['items'].map(ChatbotItem_1.ChatbotItemFromJSON)),
        'medical_record_id': json['medical_record_id'],
        'professional_id': json['professional_id'],
        'title': json['title'],
    };
}
function ChatbotToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'created_at': value['created_at'],
        'id': value['id'],
        'items': (value['items'].map(ChatbotItem_1.ChatbotItemToJSON)),
        'medical_record_id': value['medical_record_id'],
        'professional_id': value['professional_id'],
        'title': value['title'],
    };
}
