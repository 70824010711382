import React, { useReducer } from "react";
import logo from "./logo.svg";
import "./App.css";
import { ConfigProvider, Flex } from "antd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/auth/Login";
import Protected from "./components/Protected";
import Layout from "./layout/Layout";
import useTheme from "./hooks/theme/useTheme";
import AuthController from "./components/auth/AuthController";
import Dashboard from "./components/dashboard/Dashboard";
import Users from "./components/tables/Users";
import Professionals from "./components/tables/Professionals";
import Workspaces from "./components/tables/Workspaces";
import Subscriptions from "./components/tables/Subscriptions";
import Reports from "./components/tables/Reports";
import Templates from "./components/tables/Templates";
import Shortcuts from "./components/tables/Shortcuts";
import LLMPlayground from "./components/llm-playground/LLMPlayground";
import { appReducer } from "./app-state-manager/Reducers";
import { initialAppState } from "./app-state-manager/AppState";
import { AppContext } from "./app-state-manager/AppContext";
import 'dayjs/locale/fr';
import UserView from "./components/user/UserView";

const renderProtectedLayout = (component: JSX.Element) => {
  return (
    <Protected>
      <Layout>{component}</Layout>
    </Protected>
  );
};

function App() {
  const [appState, dispatch] = useReducer(appReducer, initialAppState);
  const theme = "dark"; // Vous pouvez changer entre 'light' et 'dark' ici
  const themeConfig = useTheme(theme);

  return (
    <ConfigProvider theme={themeConfig} locale={{ locale: 'fr' }}>
      <AppContext.Provider value={{appState:appState, dispatch}}>
        <BrowserRouter>
          <Routes>
            {/* TO DO : auth controller pour détecter si l'utilisateur est connecté ou pas */}
            <Route path="/login" element={<Login />} />
            <Route path="/" element={renderProtectedLayout(<Dashboard />)} />
            <Route path="/user/:userID" element={renderProtectedLayout(<UserView />)}/>
            <Route path="/users" element={renderProtectedLayout(<Users />)} />
            <Route
              path="/professionals"
              element={renderProtectedLayout(<Professionals />)}
            />
            <Route
              path="/workspaces"
              element={renderProtectedLayout(<Workspaces />)}
            />
            <Route
              path="/subscriptions"
              element={renderProtectedLayout(<Subscriptions />)}
            />
            <Route
              path="/reports"
              element={renderProtectedLayout(<Reports />)}
            />
            <Route
              path="/templates"
              element={renderProtectedLayout(<Templates />)}
            />
            <Route
              path="/shortcuts"
              element={renderProtectedLayout(<Shortcuts />)}
            />
            <Route
              path="/llm-playground"
              element={renderProtectedLayout(<LLMPlayground />)}
            />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
    </ConfigProvider>
  );
}

export default App;
