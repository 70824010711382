"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSubscriptionInput = instanceOfSubscriptionInput;
exports.SubscriptionInputFromJSON = SubscriptionInputFromJSON;
exports.SubscriptionInputFromJSONTyped = SubscriptionInputFromJSONTyped;
exports.SubscriptionInputToJSON = SubscriptionInputToJSON;
var SubscriptionItemInput_1 = require("./SubscriptionItemInput");
/**
 * Check if a given object implements the SubscriptionInput interface.
 */
function instanceOfSubscriptionInput(value) {
    if (!('customer_id' in value) || value['customer_id'] === undefined)
        return false;
    if (!('items' in value) || value['items'] === undefined)
        return false;
    return true;
}
function SubscriptionInputFromJSON(json) {
    return SubscriptionInputFromJSONTyped(json, false);
}
function SubscriptionInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'customer_id': json['customer_id'],
        'items': (json['items'].map(SubscriptionItemInput_1.SubscriptionItemInputFromJSON)),
    };
}
function SubscriptionInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'customer_id': value['customer_id'],
        'items': (value['items'].map(SubscriptionItemInput_1.SubscriptionItemInputToJSON)),
    };
}
