import { faFile, faUser, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Report,
  ReportOutput,
  ReportsApi,
  User,
  UserOutput,
  UsersApi,
} from "@thiana/api-thiana-client";
import { DatePicker, Flex, theme, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { apiConfiguration } from "../../../api/Configuration";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { countCreatedBetween, getDatesBetween } from "../../../utils/dates";
import moment from "moment";
import dayjs from "dayjs";

interface Props {
  reports: Report[];
}

export default function ActivityInfos({ reports }: Props) {
  const { token } = theme.useToken();
  const [startDate, setStartDate] = useState("01/12/2024");
  const [endDate, setEndDate] = useState("10/01/2025");
  const [data, setData] = useState<ChartData<any> | undefined>(undefined);

  useEffect(() => {
    const data = {
      labels: getDatesBetween(startDate, endDate),
      datasets: [
        {
          label: "Nouveaux reports",
          data: reports ? countCreatedBetween(reports, startDate, endDate) : [],
          backgroundColor: token.colorPrimary,
          borderColor: token.colorPrimary,
          borderWidth: 1,
        },
      ],
    };
    setData(data);
  }, [startDate, endDate, reports]);

  return (
    <Flex
      flex={1}
      style={{
        border: `1px solid ${token.colorBorder}`,
        backgroundColor: token.colorBgContainer,
        padding: 16,
        borderRadius: 16,
      }}
      vertical
    >
      <Flex align="center" style={{ color: token.colorTextBase }} gap={4}>
        <FontAwesomeIcon icon={faUser} />
        <Typography.Text style={{ fontSize: 24, fontWeight: 600 }}>
          Activité
        </Typography.Text>
      </Flex>
      <Flex justify="space-between">
        <Flex align="center" gap={4}>
          <FontAwesomeIcon icon={faFile} />
          <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>
            Evolution de l'activité (nombre de reports)
          </Typography.Text>
        </Flex>
        <Flex gap={4}>
          <DatePicker
            value={dayjs(startDate, "DD/MM/YYYY")}
            format="DD/MM/YYYY"
            onChange={(date) => {
              setStartDate(date.format("DD/MM/YYYY"));
            }}
            placeholder="Depuis"
          />
          <DatePicker
            value={dayjs(endDate, "DD/MM/YYYY")}
            format="DD/MM/YYYY"
            onChange={(date) => {
              setEndDate(date.format("DD/MM/YYYY"));
            }}
            placeholder="Jusqu'à"
          />
        </Flex>
      </Flex>
      <Flex>
        <Flex flex={2}>
          {data ? (
            <Bar
              data={data}
              options={{
                responsive: true,
                plugins: {
                  title: { display: false },
                  legend: { display: false },
                },
              }}
            />
          ) : null}
        </Flex>
        <Flex flex={1} vertical>
          <Typography.Text style={{ fontWeight: 400, fontSize: 24 }}>
            Nombre total de reports :
          </Typography.Text>
          <Typography.Text style={{ fontWeight: 400, fontSize: 32 }}>
            {reports?.length}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
