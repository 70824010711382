import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Professional, User } from "@thiana/api-thiana-client";
import { Flex, theme, Typography } from "antd";
import React from "react";

interface Props {
  user: User;
  professional: Professional;
}

export default function UserInfos({ user, professional }: Props) {
  const { token } = theme.useToken();
  return (
    <Flex
      flex={1}
      style={{
        border: `1px solid ${token.colorBorder}`,
        backgroundColor: token.colorBgContainer,
        padding: 16,
        borderRadius: 16,
      }}
      vertical
    >
      <Flex align="center" style={{ color: token.colorTextBase }} gap={4}>
        <FontAwesomeIcon icon={faUser} />
        <Typography.Text style={{ fontSize: 24, fontWeight: 600 }}>
          Informations
        </Typography.Text>
      </Flex>
      <Flex>
        <Typography.Text strong>Nom : </Typography.Text>
        <Typography.Text>{user?.last_name}</Typography.Text>
      </Flex>
      <Flex>
        <Typography.Text strong>Prénom : </Typography.Text>
        <Typography.Text>{user?.first_name}</Typography.Text>
      </Flex>
      <Flex>
        <Typography.Text strong>Date de naissance : </Typography.Text>
        <Typography.Text>{user?.birth_date}</Typography.Text>
      </Flex>
      <Flex>
        <Typography.Text strong>Téléphone : </Typography.Text>
        <Typography.Text>{professional?.phone}</Typography.Text>
      </Flex>
    </Flex>
  );
}
