"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAddressCreate201Response = instanceOfAddressCreate201Response;
exports.AddressCreate201ResponseFromJSON = AddressCreate201ResponseFromJSON;
exports.AddressCreate201ResponseFromJSONTyped = AddressCreate201ResponseFromJSONTyped;
exports.AddressCreate201ResponseToJSON = AddressCreate201ResponseToJSON;
var AddressOutput_1 = require("./AddressOutput");
/**
 * Check if a given object implements the AddressCreate201Response interface.
 */
function instanceOfAddressCreate201Response(value) {
    return true;
}
function AddressCreate201ResponseFromJSON(json) {
    return AddressCreate201ResponseFromJSONTyped(json, false);
}
function AddressCreate201ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': json['data'] == null ? undefined : (0, AddressOutput_1.AddressOutputFromJSON)(json['data']),
    };
}
function AddressCreate201ResponseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (0, AddressOutput_1.AddressOutputToJSON)(value['data']),
    };
}
