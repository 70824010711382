"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTranscriptionStreamMetadata = instanceOfTranscriptionStreamMetadata;
exports.TranscriptionStreamMetadataFromJSON = TranscriptionStreamMetadataFromJSON;
exports.TranscriptionStreamMetadataFromJSONTyped = TranscriptionStreamMetadataFromJSONTyped;
exports.TranscriptionStreamMetadataToJSON = TranscriptionStreamMetadataToJSON;
/**
 * Check if a given object implements the TranscriptionStreamMetadata interface.
 */
function instanceOfTranscriptionStreamMetadata(value) {
    return true;
}
function TranscriptionStreamMetadataFromJSON(json) {
    return TranscriptionStreamMetadataFromJSONTyped(json, false);
}
function TranscriptionStreamMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'channel_count': json['channel_count'] == null ? undefined : json['channel_count'],
        'mode': json['mode'] == null ? undefined : json['mode'],
        'navigator': json['navigator'] == null ? undefined : json['navigator'],
        'sample_rate': json['sample_rate'] == null ? undefined : json['sample_rate'],
    };
}
function TranscriptionStreamMetadataToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'channel_count': value['channel_count'],
        'mode': value['mode'],
        'navigator': value['navigator'],
        'sample_rate': value['sample_rate'],
    };
}
