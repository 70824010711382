import {
  AutomationTree,
  exampleTree,
} from "../types/LLMPlayground/AutomationTree";
import { Variable } from "../types/LLMPlayground/Variable";

export interface AppState {
  LLMPlayground: {
    status: "default" | "pending" | "running" | "error" | "finished";
    tree: AutomationTree;
    variables: Variable[];
  };
}

export const initialAppState: AppState = {
  LLMPlayground: {
    status: "default",
    tree: exampleTree,
    variables: [
      { id: "0001", name: "variable1", value: "test" },
      { id: "0002", name: "variable2", value: "test" },
      { id: "0003", name: "variable3", value: "test" },
      { id: "0004", name: "variable4", value: "test" },
      { id: "0005", name: "variable5", value: "test" },
      { id: "0006", name: "variable6", value: "test" },
    ],
  },
};
