import OpenAI from "openai";
import { CustomNode } from "../../../types/LLMPlayground/custom-nodes/CustomNode";
import { LLMNodeData } from "../../../types/LLMPlayground/custom-nodes/custom-node-data/LLMNodeData";

export async function llmApiConnection(
  node: CustomNode,
  callback: (event: any) => {},
  onClose: (event: any) => {}
): Promise<void> {
  return new Promise(async (resolve, reject) => {
    const client = new OpenAI({
      baseURL: process.env.REACT_APP_SCALEWAY_URL,
      apiKey: process.env.REACT_APP_SCALEWAY_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    if (node.type === "llm") {
      console.log("making api request");
      try {
        const stream = await client.chat.completions.create({
          model: "llama-3.1-70b-instruct",
          messages: [
            {
              role: "system",
              content: (node.data as LLMNodeData).prompts.system,
            },
            {
              role: "user",
              content: (node.data as LLMNodeData).prompts.user,
            },
          ],
          temperature: 0,
          top_p: 0.7,
          max_tokens: 2048,
          stream: true,
        });

        for await (const chunk of stream) {
          callback({
            data: JSON.stringify({
              content: chunk.choices[0]?.delta.content,
            }),
          });
        }

        resolve();
        // onClose({});
      } catch (error) {
        console.error("Erreur lors de la requête API", error);
        reject(error); // Rejet de la promesse avec l'erreur capturée
        return;
      }
    } else {
      reject();
    }
  });
}
