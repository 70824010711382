"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfEmbeddedSubscription = instanceOfEmbeddedSubscription;
exports.EmbeddedSubscriptionFromJSON = EmbeddedSubscriptionFromJSON;
exports.EmbeddedSubscriptionFromJSONTyped = EmbeddedSubscriptionFromJSONTyped;
exports.EmbeddedSubscriptionToJSON = EmbeddedSubscriptionToJSON;
/**
 * Check if a given object implements the EmbeddedSubscription interface.
 */
function instanceOfEmbeddedSubscription(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('payment_level' in value) || value['payment_level'] === undefined)
        return false;
    if (!('trial_until' in value) || value['trial_until'] === undefined)
        return false;
    return true;
}
function EmbeddedSubscriptionFromJSON(json) {
    return EmbeddedSubscriptionFromJSONTyped(json, false);
}
function EmbeddedSubscriptionFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'payment_level': json['payment_level'],
        'trial_until': json['trial_until'],
    };
}
function EmbeddedSubscriptionToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'payment_level': value['payment_level'],
        'trial_until': value['trial_until'],
    };
}
