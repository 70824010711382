"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfRegister = instanceOfRegister;
exports.RegisterFromJSON = RegisterFromJSON;
exports.RegisterFromJSONTyped = RegisterFromJSONTyped;
exports.RegisterToJSON = RegisterToJSON;
/**
 * Check if a given object implements the Register interface.
 */
function instanceOfRegister(value) {
    if (!('cgu_accepted' in value) || value['cgu_accepted'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('password' in value) || value['password'] === undefined)
        return false;
    return true;
}
function RegisterFromJSON(json) {
    return RegisterFromJSONTyped(json, false);
}
function RegisterFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'cgu_accepted': json['cgu_accepted'],
        'email': json['email'],
        'password': json['password'],
    };
}
function RegisterToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'cgu_accepted': value['cgu_accepted'],
        'email': value['email'],
        'password': value['password'],
    };
}
