"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTranscriptionRecovery = instanceOfTranscriptionRecovery;
exports.TranscriptionRecoveryFromJSON = TranscriptionRecoveryFromJSON;
exports.TranscriptionRecoveryFromJSONTyped = TranscriptionRecoveryFromJSONTyped;
exports.TranscriptionRecoveryToJSON = TranscriptionRecoveryToJSON;
/**
 * Check if a given object implements the TranscriptionRecovery interface.
 */
function instanceOfTranscriptionRecovery(value) {
    return true;
}
function TranscriptionRecoveryFromJSON(json) {
    return TranscriptionRecoveryFromJSONTyped(json, false);
}
function TranscriptionRecoveryFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'transcription': json['transcription'] == null ? undefined : json['transcription'],
    };
}
function TranscriptionRecoveryToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'transcription': value['transcription'],
    };
}
