import { jwtDecode } from "jwt-decode";
import { AccessToken } from "../types/Token";

const hasValidToken = () => {
  let encodedToken: any = localStorage.getItem("accessJWT");
  if (encodedToken === null) return false;
  let token: AccessToken = jwtDecode(encodedToken);
  if (Date.now() >= token.exp * 1000) {
    // window.location.replace("/logout");
    return false;
  }
  return true;
};

export { hasValidToken };
