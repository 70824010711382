import React from 'react'
import { hasValidToken } from '../utils/security';
import { Navigate } from 'react-router-dom';

interface Props {
    children: any
}

export default function Protected(props:Props) {
    if (!hasValidToken()) {
        return <Navigate to="/login" replace />;
      }
      return props.children;
}
