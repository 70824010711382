import {
  faChevronLeft,
  faEuro,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ApiResponse,
  Customer,
  CustomerOutput,
  PaymentsApi,
  Professional,
  ProfessionalOutput,
  ProfessionalsApi,
  ReportOutput,
  ReportsApi,
  Report,
  Subscription,
  SubscriptionOutput,
  User,
  UserOutput,
  UsersApi,
  Workspace,
  WorkspaceOutput,
  WorkspacesApi,
} from "@thiana/api-thiana-client";
import { Button, Flex, theme, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiConfiguration } from "../../api/Configuration";
import UserInfos from "./components/UserInfos";
import PaymentsInfos from "./components/PaymentsInfos";
import ActivityInfos from "./components/ActivityInfos";
import LastReports from "./components/LastReports";

export default function UserView() {
  const { token } = theme.useToken();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [professional, setProfessional] = useState<Professional | undefined>();
  const [workspace, setWorkspace] = useState<Workspace | undefined>();
  const [subscription, setSubscription] = useState<Subscription | undefined>();
  const [reports, setReports] = useState<Report[]>([]);

  const { userID } = useParams();

  const navigate = useNavigate();

  const usersApi = new UsersApi(apiConfiguration);
  const professionalsApi = new ProfessionalsApi(apiConfiguration);
  const workspacesApi = new WorkspacesApi(apiConfiguration);
  const paymentsApi = new PaymentsApi(apiConfiguration);
  const reportsApi = new ReportsApi(apiConfiguration);

  useEffect(() => {
    (async () => {
      // User
      const userResponse: ApiResponse<UserOutput> =
        await usersApi.userGetOneRaw({ id: userID as string });
      if (!userResponse.raw.ok) {
        console.error(userResponse);
      } else {
        const userOutput: UserOutput = await userResponse.raw.json();
        const user: User = userOutput.data[0];
        console.log("user", user);
        setUser(user);

        // Professional
        const professionalResponse: ApiResponse<ProfessionalOutput> =
          await professionalsApi.professionalGetOneRaw({
            id: user?.professional_id as string,
          });
        if (!professionalResponse.raw.ok) {
          console.error(professionalResponse);
        } else {
          const professionalOutput: ProfessionalOutput =
            await professionalResponse.raw.json();
          const professional: Professional = professionalOutput.data[0];
          // console.log("professional", professional);
          setProfessional(professional);

          // Workspace
          const workspaceResponse: ApiResponse<WorkspaceOutput> =
            await workspacesApi.getWorkspaceRaw({
              id: professional?.personal_workspace_id as string,
            });
          if (!workspaceResponse.raw.ok) {
            console.error(workspaceResponse);
          } else {
            const workspaceOutput: WorkspaceOutput =
              await workspaceResponse.raw.json();
            const workspace: Workspace = workspaceOutput.data[0];
            // console.log("workspace", workspace);
            setWorkspace(workspace);

            // Subscriptions
            const subscriptionResponse: ApiResponse<SubscriptionOutput> =
              await paymentsApi.subscriptionGetAllRaw({
                items: 999,
                equal: "customer_id:" + workspace.customer_id,
              });
            if (!subscriptionResponse.raw.ok) {
              console.error(subscriptionResponse);
            } else {
              const subscriptionOutput: SubscriptionOutput =
                await subscriptionResponse.raw.json();
              const subscription: Subscription = subscriptionOutput.data[0];
              console.log("subscription", subscription);
              setSubscription(subscription);
              const reportsResponse = await reportsApi.reportGetAllRaw({
                items: 999,
                equal: "professional_id:" + user.professional_id,
              });
              if (!reportsResponse.raw.ok) {
                console.error(reportsResponse);
              } else {
                const reportsOutput: ReportOutput =
                  await reportsResponse.raw.json();
                const reports: Report[] = reportsOutput.data;
                setReports(reports);
              }
            }

            // Customer
            const customerResponse: ApiResponse<CustomerOutput> =
              await paymentsApi.customerGetRaw({ id: workspace.customer_id });
            if (!customerResponse.raw.ok) {
              console.error(customerResponse);
            } else {
              const customerOutput: CustomerOutput =
                await customerResponse.raw.json();
              const customer: Customer = customerOutput.data[0];
              // console.log("customer", customer);
              // setWorkspace(workspace);
            }
          }
        }
      }
    })();
  }, []);

  return (
    <Flex
      vertical
      gap={16}
      flex={1}
      style={{ overflow: "hidden", boxSizing: "border-box" }}
    >
      <Flex gap={16} align="center">
        <Button
          onClick={() => navigate(-1)}
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
        />
        <Typography.Title>{`${user?.first_name} ${user?.last_name}`}</Typography.Title>
      </Flex>
      <Flex vertical gap={16} style={{ overflow: "scroll" }}>
        <Flex
          style={{
            borderRadius: 16,
          }}
          gap={8}
          flex={1}
        >
          <UserInfos
            user={user as User}
            professional={professional as Professional}
          />
          <Flex
            flex={1}
            style={{
              border: `1px solid ${token.colorBorder}`,
              backgroundColor: token.colorBgContainer,
              padding: 16,
              borderRadius: 16,
            }}
            vertical
          >
            {subscription ? (
              <PaymentsInfos
                subscription={subscription}
                paymentsApi={paymentsApi}
              />
            ) : null}
          </Flex>
        </Flex>
        <Flex
          style={{
            borderRadius: 16,
          }}
          gap={8}
          flex={1}
        >
          {reports ? <ActivityInfos reports={reports} /> : null}
        </Flex>
        <Flex
          style={{
            borderRadius: 16,
          }}
          gap={8}
          flex={2}
        >
          <LastReports reports={reports} />
        </Flex>
      </Flex>
    </Flex>
  );
}
