"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfReportProcessingInput = instanceOfReportProcessingInput;
exports.ReportProcessingInputFromJSON = ReportProcessingInputFromJSON;
exports.ReportProcessingInputFromJSONTyped = ReportProcessingInputFromJSONTyped;
exports.ReportProcessingInputToJSON = ReportProcessingInputToJSON;
/**
 * Check if a given object implements the ReportProcessingInput interface.
 */
function instanceOfReportProcessingInput(value) {
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('report_id' in value) || value['report_id'] === undefined)
        return false;
    return true;
}
function ReportProcessingInputFromJSON(json) {
    return ReportProcessingInputFromJSONTyped(json, false);
}
function ReportProcessingInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'content': json['content'],
        'report_id': json['report_id'],
    };
}
function ReportProcessingInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'content': value['content'],
        'report_id': value['report_id'],
    };
}
