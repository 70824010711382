"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfReportInput = instanceOfReportInput;
exports.ReportInputFromJSON = ReportInputFromJSON;
exports.ReportInputFromJSONTyped = ReportInputFromJSONTyped;
exports.ReportInputToJSON = ReportInputToJSON;
var TranscriptionInput_1 = require("./TranscriptionInput");
/**
 * Check if a given object implements the ReportInput interface.
 */
function instanceOfReportInput(value) {
    return true;
}
function ReportInputFromJSON(json) {
    return ReportInputFromJSONTyped(json, false);
}
function ReportInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'comment': json['comment'] == null ? undefined : json['comment'],
        'generation': json['generation'] == null ? undefined : json['generation'],
        'rate': json['rate'] == null ? undefined : json['rate'],
        'report_template_id': json['report_template_id'] == null ? undefined : json['report_template_id'],
        'title': json['title'] == null ? undefined : json['title'],
        'transcription': json['transcription'] == null ? undefined : json['transcription'],
        'transcriptions': json['transcriptions'] == null ? undefined : (json['transcriptions'].map(TranscriptionInput_1.TranscriptionInputFromJSON)),
    };
}
function ReportInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'comment': value['comment'],
        'generation': value['generation'],
        'rate': value['rate'],
        'report_template_id': value['report_template_id'],
        'title': value['title'],
        'transcription': value['transcription'],
        'transcriptions': value['transcriptions'] == null ? undefined : (value['transcriptions'].map(TranscriptionInput_1.TranscriptionInputToJSON)),
    };
}
