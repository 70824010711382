import { Button, Flex, Input, theme, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Variable } from "../../../types/LLMPlayground/Variable";
import { AppContext } from "../../../app-state-manager/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";

interface Props {
  variable: Variable;
}

export default function VariableListItem({ variable }: Props) {
  const [name, setName] = useState(variable.name);
  const [value, setValue] = useState(variable.value);
  const [isSaved, setIsSaved] = useState(true);

  const { token } = theme.useToken();

  useEffect(() => {
    if (isSaved) {
      setName(variable.name);
    }
  }, [variable.name]);

  useEffect(() => {
    if (isSaved) {
      setValue(variable.value);
    }
  }, [variable.value]);

  const {
    appState: {
      LLMPlayground: { variables },
    },
    dispatch,
  } = useContext(AppContext);

  const saveVariable = () => {
    let updatedVariable = { ...variable };
    updatedVariable.name = name;
    updatedVariable.value = value;

    dispatch({
      type: "@LLM_PLAYGROUND/VARIABLES/UPDATE_VARIABLES",
      payload: updatedVariable,
    });
    setIsSaved(true);
  };

  const deleteVariable = () => {
    dispatch({
      type: "@LLM_PLAYGROUND/VARIABLES/DELETE_VARIABLE",
      payload: variable.id,
    });
  };

  return (
    <Flex
      style={{
        border: `1px solid ${token.colorBorder}`,
        padding: 8,
        borderRadius: 8,
        backgroundColor: token.colorBgBase,
      }}
    >
      <Flex vertical flex={1} gap={8}>
        <Flex gap={4} vertical>
          <Typography.Text>Nom :</Typography.Text>
          <Input
            style={{ width: "100%" }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setIsSaved(false);
            }}
          />
        </Flex>
        <Flex gap={4} vertical>
          <Typography.Text>Valeur :</Typography.Text>
          <Input.TextArea
            style={{ width: "100%" }}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              setIsSaved(false);
            }}
          />
        </Flex>
        <Flex>
          <Button
            size="small"
            type="text"
            danger
            onClick={deleteVariable}
            icon={<FontAwesomeIcon icon={faTrash} />}
          >
            Supprimer
          </Button>
          <Button
            size="small"
            type="text"
            disabled={isSaved}
            onClick={saveVariable}
            icon={<FontAwesomeIcon icon={faSave} />}
          >
            Sauvegarder
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
