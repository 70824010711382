import { AutomationTree } from "../../../../types/LLMPlayground/AutomationTree";
import { CustomEdge } from "../../../../types/LLMPlayground/custom-nodes/CustomEdge";
import { CustomNode } from "../../../../types/LLMPlayground/custom-nodes/CustomNode";

export function findPrecedingLLMNodes(nodeId: string, tree: AutomationTree): CustomNode[] {
    const result: CustomNode[] = [];
  
    // Fonction pour trouver une edge qui a pour target l'id donné
    const findIncomingEdge = (targetId: string): CustomEdge | undefined => {
      return tree.edges.find((edge) => edge.target === targetId);
    };
  
    // Fonction pour récupérer un node par son id
    const findNodeById = (id: string): CustomNode | undefined => {
      return tree.nodes.find((node) => node.id === id);
    };
  
    // Récursivement chercher les nodes "llm" précédents
    const traverseBackwards = (currentNodeId: string) => {
      const incomingEdge = findIncomingEdge(currentNodeId);
  
      if (!incomingEdge) return; // Si pas de connexion avant, on arrête la récursion
  
      const previousNode = findNodeById(incomingEdge.source);
  
      if (previousNode) {
        // Vérifier si le node est de type "llm" et l'ajouter au résultat
        if (previousNode.type === "llm") {
          result.push(previousNode);
        }
        // Continuer à remonter depuis ce node
        traverseBackwards(previousNode.id);
      }
    };
  
    // Démarrer la recherche depuis le node cible
    traverseBackwards(nodeId);
  
    return result;
  }