import React, { useEffect, useState } from "react";
import { Flex, Table, theme, Typography, Modal, Button } from "antd";
import {
  ApiResponse,
  AuthApi,
  Professional,
  ProfessionalOutput,
  ProfessionalsApi,
  Report,
  ReportOutput,
  ReportsApi,
  User,
  UserOutput,
  UsersApi,
} from "@thiana/api-thiana-client";
import { apiConfiguration } from "../../api/Configuration";

export default function ReportsList() {
  const [reports, setReports] = useState<Report[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const reportsApi = new ReportsApi(apiConfiguration);
  const usersApi = new UsersApi(apiConfiguration);

  const { token } = theme.useToken();

  const columns = [
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      filters: users
        .filter((user) => user.professional_id)
        .map((user) => ({
          text: `${user.first_name} ${user.last_name} (${user.professional_id})`,
          value: user.professional_id || "",
        })),
        onFilter: (value:any, record:Report) => record.professional_id as string === value,

    },
    // {
    //   title: "Report ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    // {
    //   title: "Report Template ID",
    //   dataIndex: "report_template_id",
    //   key: "report_template_id",
    // },
    // {
    //   title: "Generation Date",
    //   dataIndex: "generation",
    //   key: "generation",
    //   render: (text: string) => {
    //     const parser = new DOMParser();
    //     const doc = parser.parseFromString(text, "text/html");
    //     return <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />;
    //   },
    // },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    // {
    //   title: "Transcription",
    //   dataIndex: "transcription",
    //   key: "transcription",
    //   render: (text: string) => <div>{text.length > 100 ? text.substring(0, 100) + "..." : text}</div>, // Limiter l'affichage du texte
    // },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: Report) => (
        <Button
          type="text"
          onClick={() => {
            setSelectedReport(record);
            setIsModalVisible(true);
          }}
        >
          Afficher
        </Button>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      let reports: Report[] = [];
      const reportResponse: ApiResponse<ReportOutput> =
        await reportsApi.reportGetAllRaw({ items: 999 });

      if (!reportResponse.raw.ok) console.error(reportResponse);
      else {
        const reportOutput: ReportOutput = await reportResponse.raw.json();
        reports = reportOutput.data;
      }

      const usersResponse = await usersApi.userGetAllRaw({ items: 999 });
      let users: User[] = [];
      if (!usersResponse.raw.ok) console.error(usersResponse);
      else {
        const userOutput: UserOutput = await usersResponse.raw.json();
        users = userOutput.data;
        setUsers(users);

        // Mapping reports and adding the "professionnal" key
        const enrichedReports = reports.map((report) => {
          const matchingUser = users.find(
            (user) => user.professional_id === report.professional_id
          );
          return {
            ...report,
            user: matchingUser
              ? matchingUser.first_name + " " + matchingUser.last_name
              : null, // or a default value
          };
        });

        setReports(enrichedReports);
      }
    })();
  }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Flex vertical gap={32} flex={1}>
      <Typography.Title>Reports</Typography.Title>
      <Flex
        style={{
          border: "1px solid " + token.colorBorder,
          borderRadius: 16,
        }}
      >
        <Table
          style={{ width: "100%", overflow: "scroll", borderRadius: 16 }}
          pagination={{ pageSize: 16 }}
          columns={columns}
          dataSource={reports}
          scroll={{ y: 700 }}
        ></Table>
      </Flex>

      <Modal
        title="Détails"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closeIcon={
          <Button type="text" onClick={handleCancel}>
            ×
          </Button>
        }
        width={800}
      >
        {selectedReport && (
          <Flex>
            {/* Transcription à gauche */}
            <div style={{ flex: 1, paddingRight: 16 }}>
              <Typography.Title level={4}>Transcription</Typography.Title>
              <div>{selectedReport.transcription}</div>
            </div>

            {/* Generation à droite */}
            <div style={{ flex: 1 }}>
              <Typography.Title level={4}>Generation</Typography.Title>
              <div
                dangerouslySetInnerHTML={{
                  __html: new DOMParser().parseFromString(
                    selectedReport.generation,
                    "text/html"
                  ).body.innerHTML,
                }}
              />
            </div>
          </Flex>
        )}
      </Modal>
    </Flex>
  );
}
