"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSessionItem = instanceOfSessionItem;
exports.SessionItemFromJSON = SessionItemFromJSON;
exports.SessionItemFromJSONTyped = SessionItemFromJSONTyped;
exports.SessionItemToJSON = SessionItemToJSON;
/**
 * Check if a given object implements the SessionItem interface.
 */
function instanceOfSessionItem(value) {
    if (!('price_id' in value) || value['price_id'] === undefined)
        return false;
    if (!('quantity' in value) || value['quantity'] === undefined)
        return false;
    return true;
}
function SessionItemFromJSON(json) {
    return SessionItemFromJSONTyped(json, false);
}
function SessionItemFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'price_id': json['price_id'],
        'quantity': json['quantity'],
    };
}
function SessionItemToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'price_id': value['price_id'],
        'quantity': value['quantity'],
    };
}
