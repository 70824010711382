"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfDocumentUpdate200Response = instanceOfDocumentUpdate200Response;
exports.DocumentUpdate200ResponseFromJSON = DocumentUpdate200ResponseFromJSON;
exports.DocumentUpdate200ResponseFromJSONTyped = DocumentUpdate200ResponseFromJSONTyped;
exports.DocumentUpdate200ResponseToJSON = DocumentUpdate200ResponseToJSON;
var DocumentOutput_1 = require("./DocumentOutput");
/**
 * Check if a given object implements the DocumentUpdate200Response interface.
 */
function instanceOfDocumentUpdate200Response(value) {
    return true;
}
function DocumentUpdate200ResponseFromJSON(json) {
    return DocumentUpdate200ResponseFromJSONTyped(json, false);
}
function DocumentUpdate200ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': json['data'] == null ? undefined : (0, DocumentOutput_1.DocumentOutputFromJSON)(json['data']),
    };
}
function DocumentUpdate200ResponseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (0, DocumentOutput_1.DocumentOutputToJSON)(value['data']),
    };
}
