"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMedicalRecordOutput = instanceOfMedicalRecordOutput;
exports.MedicalRecordOutputFromJSON = MedicalRecordOutputFromJSON;
exports.MedicalRecordOutputFromJSONTyped = MedicalRecordOutputFromJSONTyped;
exports.MedicalRecordOutputToJSON = MedicalRecordOutputToJSON;
var Metadata_1 = require("./Metadata");
var MedicalRecord_1 = require("./MedicalRecord");
/**
 * Check if a given object implements the MedicalRecordOutput interface.
 */
function instanceOfMedicalRecordOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
function MedicalRecordOutputFromJSON(json) {
    return MedicalRecordOutputFromJSONTyped(json, false);
}
function MedicalRecordOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(MedicalRecord_1.MedicalRecordFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
function MedicalRecordOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(MedicalRecord_1.MedicalRecordToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
