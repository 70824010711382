import { Button } from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ReactFlow,
  MiniMap,
  Controls,
  Background,
  NodeToolbar,
  useNodesState,
  useEdgesState,
  addEdge,
  useOnViewportChange,
  Edge,
  NodeChange,
  applyNodeChanges,
  applyEdgeChanges,
  EdgeChange,
  Connection,
} from "@xyflow/react";

import LLMNode from "../custom-nodes/LLMNode";
import StartNode from "../custom-nodes/StartNode";
import EndNode from "../custom-nodes/EndNode";
import "@xyflow/react/dist/style.css";
import { getLayoutedElements } from "./functions.getLayoutedElements";
import { CustomEdge } from "../../../types/LLMPlayground/custom-nodes/CustomEdge";
import { AppContext } from "../../../app-state-manager/AppContext";
import { CustomNode } from "../../../types/LLMPlayground/custom-nodes/CustomNode";

export default function FlowPannel() {
  const {
    appState: {
      LLMPlayground: { tree },
    },
    dispatch,
  } = useContext(AppContext);

  // const [nodes, setNodes, onNodesChange] = useNodesState(props.nodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(props.edges);
  const [isDragging, setIsDragging] = useState(false);

  const nodeTypes = useMemo(
    () => ({
      llm: LLMNode,
      start: StartNode,
      end: EndNode,
    }),
    []
  );

  const onNodesChange = useCallback(
    (changes: NodeChange<CustomNode>[]) => {
      const updatedNodes = applyNodeChanges(changes, tree.nodes);
      dispatch({ type: "@LLM_PLAYGROUND/UPDATE_NODES_FROM_PANNEL", payload: updatedNodes });
    },
    [tree.nodes, dispatch]
  );

  const onEdgesChange = useCallback(
    (changes: EdgeChange<CustomEdge>[]) => {
      const updatedEdges = applyEdgeChanges(changes, tree.edges);
      dispatch({ type: "@LLM_PLAYGROUND/UPDATE_EDGES", payload: updatedEdges });
    },
    [tree.edges, dispatch]
  );

  const onConnect = useCallback(
    (connection: Connection) => {
      const newEdges = addEdge(connection, tree.edges);
      
      dispatch({
        type: "@LLM_PLAYGROUND/UPDATE_EDGES",
        payload: newEdges,
      });
    },
    [tree.edges, dispatch]
  );

  // useEffect(() => {
  //   setEdges(props.edges);
  // }, [props.edges]);

  // useEffect(() => {
  //   setNodes(props.nodes);
  // }, [props.nodes]);

  // useEffect(() => {
  //   const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  //     nodes,
  //     edges
  //   );
  //   setNodes([...layoutedNodes]);
  //   setEdges([...layoutedEdges]);
  // }, [nodes, edges]);

  useOnViewportChange({
    onStart: () => setIsDragging(true),
    onEnd: () => setIsDragging(false),
  });

  // const onEdgesDelete = useCallback((edgesToDelete: CustomEdge[]) => {
  //   setEdges((eds) =>
  //     eds.filter((edge) => !edgesToDelete.some((e) => e.id === edge.id))
  //   );
  // }, []);

  return (
    <ReactFlow
      nodes={tree.nodes}
      edges={tree.edges}
      nodeTypes={nodeTypes}
      onNodesChange={(changes) => onNodesChange(changes)}
      onEdgesChange={(changes) => onEdgesChange(changes)}
      nodesDraggable={true}
      // onEdgesDelete={onEdgesDelete}
      onConnect={(connection) => onConnect(connection)}
      fitView
      zoomOnScroll={false}
      panOnScroll={false}
      zoomOnDoubleClick={false}
      panOnDrag={true}
      proOptions={{ hideAttribution: true }}
    >
      <Controls />
      {isDragging && <MiniMap />}
      <Background gap={12} size={1} />
      <NodeToolbar isVisible={true}>
        <Button>Test</Button>
      </NodeToolbar>
    </ReactFlow>
  );
}
