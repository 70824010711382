import { AuthApi, Me, MeOutput } from "@thiana/api-thiana-client";
import { Button, Flex, Input, theme, Typography } from "antd";
import React, { useState } from "react";
import { apiConfiguration } from "../../api/Configuration";
import { Navigate, useNavigate } from "react-router-dom";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate()

  const { token } = theme.useToken();

  const authApi = new AuthApi(apiConfiguration);

  const login = async () => {
    const loginResponse = await authApi.authUserLoginRaw({
      loginInput: { email, password },
    });
    if (!loginResponse.raw.ok) console.error(loginResponse);
    else {
      const userMeRawResponse = await authApi.authUserMeRaw();
      if (!userMeRawResponse.raw.ok) console.error(userMeRawResponse);
      else {
        const userMeData : MeOutput = await userMeRawResponse.raw.json();
        const userMe : Me = userMeData.data[0]
        const scopeResponse = await authApi.authScopeRaw({
          scopeInput: {
            scope: 4,
            workspace_id: userMe.professional.personal_workspace_id 
          },
        });
        if (!scopeResponse.raw.ok) {
          console.error(scopeResponse);
        } else {
          return navigate("/");
        }
      }
    }
  };

  return (
    <Flex
      justify="center"
      align="center"
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: token.colorBgBase,
      }}
    >
      <Flex
        vertical
        style={{
          width: 800,
          padding: 32,
          border: "1px solid " + token.colorBorder,
          backgroundColor: token.colorBgContainer,
          borderRadius: 16,
        }}
      >
        <Typography.Title level={2}>Connexion</Typography.Title>
        <Flex vertical gap={8}>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button type="primary" onClick={async () => await login()}>
            Connexion
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
