import React, { useContext } from "react";
import { CustomNode } from "../../types/LLMPlayground/custom-nodes/CustomNode";
import { llmApiConnection } from "./functions/llmApiConnection";
import { LLMNodeData } from "../../types/LLMPlayground/custom-nodes/custom-node-data/LLMNodeData";
import { CustomEdge } from "../../types/LLMPlayground/custom-nodes/CustomEdge";
import { AutomationTree } from "../../types/LLMPlayground/AutomationTree";
import { Variable } from "../../types/LLMPlayground/Variable";
import { Action } from "../../app-state-manager/ActionTypes";
import { AppContext } from "../../app-state-manager/AppContext";

export default function useLLMPipeline() {
  // const {appState : {LLMPlayground: {tree, variables}}, dispatch} = useContext(AppContext)
  const launchPipeline = async (
    tree: AutomationTree,
    variables: Variable[],
    dispatch: React.Dispatch<Action>
  ): Promise<void> => {
    console.log("tree", tree);
    return new Promise(async (resolve, reject) => {
      let finished = await processNode(
        tree.nodes.find(
          (node: CustomNode) => node.type === "start"
        ) as CustomNode,
        tree,
        variables,
        dispatch
      );
      if (finished) resolve();
    });
  };

  const processNode = async (
    node: CustomNode,
    tree: AutomationTree,
    variables: Variable[],
    dispatch: React.Dispatch<Action>
  ) => {
    try {
      console.log("Traitement du nœud", node);
      if (node.type === "end") {
        console.log("Pipeline terminée");
        return true;
      } else if (node.type === "start") {
        console.log("Début de la pipeline");
      } else if (node.type === "llm") {
        console.log("llm node", node);
        await processLlmNode(node, tree, variables, dispatch);
      }

      const nextNode = getNextNode(node, tree.nodes, tree.edges);
      if (nextNode) {
        await processNode(nextNode, tree, variables, dispatch);
      }
    } catch (error) {
      console.error(
        `Erreur dans processNode pour le nœud ${node.id || "inconnu"}`,
        error
      );
      throw error; // Propager l'erreur pour un traitement en amont
    }
  };

  const processLlmNode = async (
    node: CustomNode,
    tree: AutomationTree,
    variables: Variable[],
    dispatch: React.Dispatch<Action>
  ): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedNode = structuredClone(node);
        console.log("updatedNode", updatedNode);
        (updatedNode.data as LLMNodeData).status = "pending";
        console.log("dispatch pending");
        dispatch({ type: "@LLM_PLAYGROUND/UPDATE_NODE", payload: updatedNode });

        (updatedNode.data as LLMNodeData).status = "processing";

        let nodeToProcess = structuredClone(node);
        nodeToProcess.data = preprocessData(
          node.data as LLMNodeData,
          variables
        );
        console.log(nodeToProcess.data.prompts);

        await llmApiConnection(
          nodeToProcess,
          async (event: any) => {
            const data = JSON.parse(event.data);
            // console.log("data reçu", data);
            (updatedNode.data as LLMNodeData).prompts.assistant =
              (updatedNode.data as LLMNodeData).prompts.assistant +
              data.content;
            dispatch({
              type: "@LLM_PLAYGROUND/UPDATE_NODE_FROM_LLM",
              payload: updatedNode,
            });
          },
          async (event: any) => {
            console.error("Erreur dans llmApiConnection", event);
            (updatedNode.data as LLMNodeData).status = "error";
            dispatch({
              type: "@LLM_PLAYGROUND/UPDATE_NODE",
              payload: updatedNode,
            });
            reject(new Error("Erreur dans llmApiConnection")); // Rejeter avec un message clair
          }
        );

        (updatedNode.data as LLMNodeData).status = "success";
        console.log("updatedNode.data", updatedNode.data);
        postProcessData(
          updatedNode.data as LLMNodeData,
          tree.nodes,
          variables,
          dispatch
        );

        resolve();
      } catch (error) {
        console.error("Erreur dans processLlmNode", error);
        reject(error); // Propager l'erreur pour que processNode la capte
      }
    });
  };

  const getNextNode = (
    currentNode: CustomNode,
    nodes: CustomNode[],
    edges: CustomEdge[]
  ) => {
    // Trouver l'arête correspondant à la source actuelle
    const edge = edges.find((e) => e.source === currentNode.id);
    console.log("edge found", edge);
    if (!edge) return null;

    // Trouver le noeud cible correspondant à l'arête
    const nextNode = nodes.find((n) => n.id === edge.target);
    console.log("nextNode", nextNode);
    return nextNode || null;
  };

  const preprocessData = (
    data: LLMNodeData,
    variables: Variable[]
  ): LLMNodeData => {
    const regex = /\{([^}]*)\}/g; // Expression régulière pour capturer tout texte entre {}
    let dataToReturn = structuredClone(data);

    ["system", "assistant"].forEach((type: string) => {
      const matches = data.prompts[type as "system" | "assistant"].match(regex);
      if (matches) {
        matches.forEach((matchExpression: string) => {
          if (matchExpression) {
            const matchedName = matchExpression.slice(1, -1);
            const isVariable = variables.some(
              (variable) => variable.name === matchedName
            );
            if (isVariable) {
              const textToReplace = variables.filter(
                (variable: Variable) => variable.name === matchedName
              )[0].value;
              dataToReturn.prompts.assistant.replace(
                matchExpression,
                textToReplace
              );
            }
          }
        });
      } else {
        console.log("Aucun match détectés");
      }
    });
    return dataToReturn;
  };

  const postProcessData = (
    data: LLMNodeData,
    nodes: CustomNode[],
    variables: Variable[],
    dispatch: React.Dispatch<Action>
  ): void => {
    if (data.action.location) {
      const [exportType, id, prompt] = (
        data.action.location as
          | `${string}:${string}:${string}`
          | `${string}:${string}`
      ).split(":");

      console.log("[exportType, id, prompt]", [exportType, id, prompt]);

      if (data.action.type === "add") {
        if (exportType === "variable") {
          let variableToEdit: Variable | undefined = variables.find(
            (variable: Variable) => variable.id === id
          );
          if (variableToEdit) {
            variableToEdit.value =
              variableToEdit.value + "\n" + data.prompts.assistant;
            dispatch({
              type: "@LLM_PLAYGROUND/VARIABLES/UPDATE_VARIABLES",
              payload: variableToEdit,
            });
          } else {
            console.error("variable not found");
          }
        }
      } else if (data.action.type === "replace") {
        if (exportType === "variable") {
          let variableToEdit = variables.filter(
            (variable: Variable) => variable.id === id
          )[0];

          variableToEdit.value = variableToEdit.value.replaceAll(
            data.action.textToReplace,
            data.prompts.assistant
          );
          dispatch({
            type: "@LLM_PLAYGROUND/VARIABLES/UPDATE_VARIABLES",
            payload: variableToEdit,
          });
        } else if (exportType === "node") {
          let nodeToEdit = nodes.filter(
            (node: CustomNode) => node.id === "id"
          )[0];
          (nodeToEdit.data as LLMNodeData).prompts[
            prompt as "system" | "user" | "assistant"
          ].replaceAll(data.action.textToReplace, data.prompts.assistant);
          dispatch({
            type: "@LLM_PLAYGROUND/UPDATE_NODE",
            payload: nodeToEdit,
          });
        }
      }
    }
    console.log("No location found");
  };

  return { launchPipeline };
}
