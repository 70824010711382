import { Flex, theme, Typography } from "antd";
import { Handle, Position, useHandleConnections } from "@xyflow/react";

export default function EndNode() {
  const targetConnections = useHandleConnections({
    type: "target",
  });
  const { token } = theme.useToken();
  return (
    <Flex
      vertical
      gap={4}
      justify="center"
      align="center"
      style={{
        border: `1px solid ${token.colorBorder}`,
        backgroundColor: token.colorBgBase,
        borderRadius: 16,
        paddingRight: 32,
        paddingLeft: 32,
      }}
    >
      <Handle
        position={Position.Top}
        type="target"
        isConnectable={targetConnections.length < 1}
      />
      <Typography.Title level={3}>FIN</Typography.Title>
    </Flex>
  );
}
