import { faEuro } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PaymentsApi,
  Subscription,
} from "@thiana/api-thiana-client";
import { Button, DatePicker, Drawer, Flex, theme, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

interface Props {
  subscription: Subscription;
  paymentsApi: PaymentsApi;
}

interface SubscriptionItem {
  id: string;
  payment_level: string;
  quantity: number;
  recurring_interval: string;
  status: string;
  trial_until: string;
  valid_until: string;
}

export default function PaymentsInfos({ subscription, paymentsApi }: Props) {
  const { token } = theme.useToken();

  const [subscriptionItem, setSubscriptionItem] = useState<SubscriptionItem | undefined>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    console.log(subscription);
    setSubscriptionItem(subscription.items[0] as SubscriptionItem);
  }, []);

  const updatePaymentsInfos = async () => {
    const paymentResponse = await paymentsApi.subscriptionUpdateRaw({
      id: subscription.id,
      subscriptionInput: {
        customer_id: subscription.customer_id,
        items: [{ ...subscriptionItem as SubscriptionItem}],
      },
    });
  };

  return (
    <Flex style={{ color: token.colorTextBase }} gap={4} vertical>
      <Flex justify="space-between">
        <Flex align="center" gap={4}>
          <FontAwesomeIcon icon={faEuro} />
          <Typography.Text style={{ fontSize: 24, fontWeight: 600 }}>
            Abonnement (subscription)
          </Typography.Text>
        </Flex>
        <Button onClick={() => setIsDrawerOpen(true)}>Modifier</Button>
      </Flex>
      <Flex>
        <Typography.Text strong>Status : </Typography.Text>
        <Typography.Text>{subscriptionItem?.status}</Typography.Text>
      </Flex>

      <Flex>
        <Typography.Text strong>Niveau de paiement : </Typography.Text>
        <Typography.Text>{subscriptionItem?.payment_level}</Typography.Text>
      </Flex>
      <Flex>
        <Typography.Text strong>Echéance période d'essai : </Typography.Text>
        <Typography.Text>{subscriptionItem?.trial_until}</Typography.Text>
      </Flex>
      <Flex>
        <Typography.Text strong>Valide jusqu'à : </Typography.Text>
        <Typography.Text>{subscriptionItem?.valid_until}</Typography.Text>
      </Flex>

      <Flex>
        <Typography.Text strong>Renouvellement : </Typography.Text>
        <Typography.Text>
          {subscriptionItem?.recurring_interval}
        </Typography.Text>
      </Flex>
      <Drawer
        title="Modification de l'abonnement"
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
      >
        <Flex gap={8} vertical>
          <Flex vertical gap={4}>
            <Typography.Text>Période d'essai</Typography.Text>
            <DatePicker
              value={dayjs(
                subscriptionItem?.trial_until
                  ? subscriptionItem?.trial_until
                  : null,
                "DD/MM/YYYY hh:mm:ss"
              )}
              onChange={(date) => {
                setSubscriptionItem({
                  ...subscriptionItem,
                  trial_until: date.format("DD/MM/YYYY hh:mm:ss"),
                } as SubscriptionItem);
              }}
              showTime
            />
          </Flex>
          <Button
            type="primary"
            danger
            onClick={async () => await updatePaymentsInfos()}
          >
            Modifier la période d'essai.
          </Button>
        </Flex>
      </Drawer>
    </Flex>
  );
}
