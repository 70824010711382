"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfReportSnapshotOutput = instanceOfReportSnapshotOutput;
exports.ReportSnapshotOutputFromJSON = ReportSnapshotOutputFromJSON;
exports.ReportSnapshotOutputFromJSONTyped = ReportSnapshotOutputFromJSONTyped;
exports.ReportSnapshotOutputToJSON = ReportSnapshotOutputToJSON;
var ReportSnapshot_1 = require("./ReportSnapshot");
var Metadata_1 = require("./Metadata");
/**
 * Check if a given object implements the ReportSnapshotOutput interface.
 */
function instanceOfReportSnapshotOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
function ReportSnapshotOutputFromJSON(json) {
    return ReportSnapshotOutputFromJSONTyped(json, false);
}
function ReportSnapshotOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(ReportSnapshot_1.ReportSnapshotFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
function ReportSnapshotOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(ReportSnapshot_1.ReportSnapshotToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
