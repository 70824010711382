"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfEntry = instanceOfEntry;
exports.EntryFromJSON = EntryFromJSON;
exports.EntryFromJSONTyped = EntryFromJSONTyped;
exports.EntryToJSON = EntryToJSON;
/**
 * Check if a given object implements the Entry interface.
 */
function instanceOfEntry(value) {
    if (!('code' in value) || value['code'] === undefined)
        return false;
    if (!('label' in value) || value['label'] === undefined)
        return false;
    return true;
}
function EntryFromJSON(json) {
    return EntryFromJSONTyped(json, false);
}
function EntryFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'code': json['code'],
        'label': json['label'],
    };
}
function EntryToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'code': value['code'],
        'label': value['label'],
    };
}
