"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfScopeInput = instanceOfScopeInput;
exports.ScopeInputFromJSON = ScopeInputFromJSON;
exports.ScopeInputFromJSONTyped = ScopeInputFromJSONTyped;
exports.ScopeInputToJSON = ScopeInputToJSON;
/**
 * Check if a given object implements the ScopeInput interface.
 */
function instanceOfScopeInput(value) {
    if (!('scope' in value) || value['scope'] === undefined)
        return false;
    if (!('workspace_id' in value) || value['workspace_id'] === undefined)
        return false;
    return true;
}
function ScopeInputFromJSON(json) {
    return ScopeInputFromJSONTyped(json, false);
}
function ScopeInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'scope': json['scope'],
        'workspace_id': json['workspace_id'],
    };
}
function ScopeInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'scope': value['scope'],
        'workspace_id': value['workspace_id'],
    };
}
