"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfUserOutput = instanceOfUserOutput;
exports.UserOutputFromJSON = UserOutputFromJSON;
exports.UserOutputFromJSONTyped = UserOutputFromJSONTyped;
exports.UserOutputToJSON = UserOutputToJSON;
var User_1 = require("./User");
var Metadata_1 = require("./Metadata");
/**
 * Check if a given object implements the UserOutput interface.
 */
function instanceOfUserOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
function UserOutputFromJSON(json) {
    return UserOutputFromJSONTyped(json, false);
}
function UserOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(User_1.UserFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
function UserOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(User_1.UserToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
