import { AutomationTree } from "../types/LLMPlayground/AutomationTree";
import { Variable } from "../types/LLMPlayground/Variable";
import { Action } from "./ActionTypes";
import { AppState } from "./AppState";

const logReducerEvolution = (
  state: AppState,
  action: Action,
  updatedState: AppState
) => {
  console.groupCollapsed("Action.type: ", action.type);
  console.log("state :", state);
  console.log("action.payload :", action.payload);
  console.log("updatedState :", updatedState);
  console.groupEnd();
};

export const appReducer = (state: AppState, action: Action) => {
  let updatedState: AppState;
  switch (action.type) {
    case "@INITIALIZE":
      updatedState = { ...state };
      logReducerEvolution(state, action, updatedState);
      return updatedState;

    case "@LLM_PLAYGROUND/UPDATE_NODES":
      updatedState = {
        ...state,
        LLMPlayground: {
          ...state.LLMPlayground,
          tree: {
            ...(state.LLMPlayground.tree as AutomationTree),
            nodes: action.payload,
          },
        },
      };
      logReducerEvolution(state, action, updatedState);
      return updatedState;

      case "@LLM_PLAYGROUND/UPDATE_NODES_FROM_PANNEL":
      updatedState = {
        ...state,
        LLMPlayground: {
          ...state.LLMPlayground,
          tree: {
            ...(state.LLMPlayground.tree as AutomationTree),
            nodes: action.payload,
          },
        },
      };
      // logReducerEvolution(state, action, updatedState);
      return updatedState;

    case "@LLM_PLAYGROUND/ADD_NODE":
      updatedState = {
        ...state,
        LLMPlayground: {
          ...state.LLMPlayground,
          tree: {
            ...(state.LLMPlayground.tree as AutomationTree),
            nodes: [...state.LLMPlayground.tree.nodes, action.payload],
          },
        },
      };
      logReducerEvolution(state, action, updatedState);
      return updatedState;

    case "@LLM_PLAYGROUND/UPDATE_NODE":
      updatedState = {
        ...state,
        LLMPlayground: {
          ...state.LLMPlayground,
          tree: {
            ...(state.LLMPlayground.tree as AutomationTree),
            nodes: state.LLMPlayground.tree.nodes.map((node) =>
              node.id === action.payload.id ? action.payload : node
            ),
          },
        },
      };
      logReducerEvolution(state, action, updatedState);
      return updatedState;

      case "@LLM_PLAYGROUND/UPDATE_NODE_FROM_LLM":
      updatedState = {
        ...state,
        LLMPlayground: {
          ...state.LLMPlayground,
          tree: {
            ...(state.LLMPlayground.tree as AutomationTree),
            nodes: state.LLMPlayground.tree.nodes.map((node) =>
              node.id === action.payload.id ? action.payload : node
            ),
          },
        },
      };
      // logReducerEvolution(state, action, updatedState);
      return updatedState;


    case "@LLM_PLAYGROUND/UPDATE_EDGES":
      updatedState = {
        ...state,
        LLMPlayground: {
          ...state.LLMPlayground,
          tree: {
            ...(state.LLMPlayground.tree as AutomationTree),
            edges: action.payload,
          },
        },
      };
      logReducerEvolution(state, action, updatedState);
      return updatedState;

    case "@LLM_PLAYGROUND/VARIABLES/UPDATE_VARIABLES":
      updatedState = {
        ...state,
        LLMPlayground: {
          ...state.LLMPlayground,
          variables: state.LLMPlayground.variables.map((v: Variable) => {
            return v.id === action.payload.id ? action.payload : v;
          }),
        },
      };
      logReducerEvolution(state, action, updatedState);
      return updatedState;

    case "@LLM_PLAYGROUND/VARIABLES/DELETE_ALL":
      updatedState = {
        ...state,
        LLMPlayground: {
          ...state.LLMPlayground,
          variables: [],
        },
      };
      logReducerEvolution(state, action, updatedState);
      return updatedState;

    case "@LLM_PLAYGROUND/VARIABLES/DELETE_VARIABLE":
      updatedState = {
        ...state,
        LLMPlayground: {
          ...state.LLMPlayground,
          variables: [...state.LLMPlayground.variables.filter((variable) => variable.id !== action.payload)]
        },
      };
      logReducerEvolution(state, action, updatedState);
      return updatedState;

    default:
      console.error("Unrecocnized action type.");
      return state;
  }
};
