import { useMemo } from "react";

const useTheme = (theme: "light" | "dark") => {
  const themeConfig = useMemo(() => {
    if (theme === "light") {
      return {
        token: {
          fontFamily: '"Nunito", sans-serif', // Police du texte principal
          fontFamilyHeading: '"Nunito", sans-serif', // Police des titres
          colorPrimary: "#0D41E1", // Couleur primaire
          colorSecondary: "#07C8F9", // Couleur secondaire
          colorBgBase: "#F0F4F9", // Fond de l'application
          colorBgContainer: "#F7F8FA", // Fond des containers
          colorTextHeading: "#0F1026", // Titres
          colorTextBase: "#2C2E3E", // Texte principal
          colorTextSecondary: "#6C6F83", // Texte secondaire
          colorLink: "#0D41E1", // Couleur des liens
          colorBorder: "#D1D4E4", // Bordures
          colorShadowBase: "rgba(0, 0, 0, 0.1)", // Ombre légère
          colorShadowMedium: "rgba(0, 0, 0, 0.2)", // Ombre moyenne
          colorShadowLarge: "rgba(0, 0, 0, 0.3)", // Ombre large
          Button: {
            primaryShadow: "none",
          },
        },
      };
    } else {
      return {
        token: {
          fontFamily: '"Nunito", sans-serif', // Police du texte principal
          fontFamilyHeading: '"Nunito", sans-serif', // Police des titres
          colorPrimary: "#0D41E1", // Couleur primaire
          colorSecondary: "#07C8F9", // Couleur secondaire
          colorBgBase: "#1A2539", // Fond de l'application
          colorBgContainer: "#121C39", // Fond des containers
          colorTextHeading: "#F7F8FA", // Titres
          colorTextBase: "#E0E3F3", // Texte principal
          colorTextSecondary: "#A2A8C2", // Texte secondaire
          colorLink: "#0D41E1", // Couleur des liens
          colorBorder: "#343A50", // Bordures
          colorShadowBase: "rgba(0, 0, 0, 0.3)", // Ombre légère
          colorShadowMedium: "rgba(0, 0, 0, 0.4)", // Ombre moyenne
          colorShadowLarge: "rgba(0, 0, 0, 0.5)", // Ombre large
          Button: {
            primaryShadow: "none",
            defaultShadow: "none",
            dangerShadow: "none",
          },
        },
      };
    }
  }, [theme]);

  return themeConfig;
};

export default useTheme;
