"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfConversationOutput = instanceOfConversationOutput;
exports.ConversationOutputFromJSON = ConversationOutputFromJSON;
exports.ConversationOutputFromJSONTyped = ConversationOutputFromJSONTyped;
exports.ConversationOutputToJSON = ConversationOutputToJSON;
var Metadata_1 = require("./Metadata");
var Conversation_1 = require("./Conversation");
/**
 * Check if a given object implements the ConversationOutput interface.
 */
function instanceOfConversationOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
function ConversationOutputFromJSON(json) {
    return ConversationOutputFromJSONTyped(json, false);
}
function ConversationOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(Conversation_1.ConversationFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
function ConversationOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(Conversation_1.ConversationToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
