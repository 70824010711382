"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfHTMLToPDF400Response = instanceOfHTMLToPDF400Response;
exports.HTMLToPDF400ResponseFromJSON = HTMLToPDF400ResponseFromJSON;
exports.HTMLToPDF400ResponseFromJSONTyped = HTMLToPDF400ResponseFromJSONTyped;
exports.HTMLToPDF400ResponseToJSON = HTMLToPDF400ResponseToJSON;
/**
 * Check if a given object implements the HTMLToPDF400Response interface.
 */
function instanceOfHTMLToPDF400Response(value) {
    return true;
}
function HTMLToPDF400ResponseFromJSON(json) {
    return HTMLToPDF400ResponseFromJSONTyped(json, false);
}
function HTMLToPDF400ResponseFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'message': json['message'] == null ? undefined : json['message'],
    };
}
function HTMLToPDF400ResponseToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'message': value['message'],
    };
}
