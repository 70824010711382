"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSecretary = instanceOfSecretary;
exports.SecretaryFromJSON = SecretaryFromJSON;
exports.SecretaryFromJSONTyped = SecretaryFromJSONTyped;
exports.SecretaryToJSON = SecretaryToJSON;
/**
 * Check if a given object implements the Secretary interface.
 */
function instanceOfSecretary(value) {
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    return true;
}
function SecretaryFromJSON(json) {
    return SecretaryFromJSONTyped(json, false);
}
function SecretaryFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'created_at': json['created_at'],
        'deleted_at': json['deleted_at'],
        'id': json['id'],
        'professional_id': json['professional_id'],
        'updated_at': json['updated_at'],
    };
}
function SecretaryToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'created_at': value['created_at'],
        'deleted_at': value['deleted_at'],
        'id': value['id'],
        'professional_id': value['professional_id'],
        'updated_at': value['updated_at'],
    };
}
