"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPatientOutput = instanceOfPatientOutput;
exports.PatientOutputFromJSON = PatientOutputFromJSON;
exports.PatientOutputFromJSONTyped = PatientOutputFromJSONTyped;
exports.PatientOutputToJSON = PatientOutputToJSON;
var Metadata_1 = require("./Metadata");
var Patient_1 = require("./Patient");
/**
 * Check if a given object implements the PatientOutput interface.
 */
function instanceOfPatientOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
function PatientOutputFromJSON(json) {
    return PatientOutputFromJSONTyped(json, false);
}
function PatientOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(Patient_1.PatientFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
function PatientOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(Patient_1.PatientToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
