import React, { useEffect, useState } from "react";
import { Flex, Table, theme, Typography, Modal, Button, Segmented } from "antd";
import {
  ApiResponse,
  ReportTemplate,
  ReportTemplateOutput,
  TemplateReportsApi,
  ReportTemplateShortcut,
} from "@thiana/api-thiana-client";
import { apiConfiguration } from "../../api/Configuration";

export default function ReportTemplatesList() {
  const [reportTemplates, setReportTemplates] = useState<ReportTemplate[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReportTemplate, setSelectedReportTemplate] = useState<ReportTemplate | null>(null);
  const [shortcuts, setShortcuts] = useState<ReportTemplateShortcut[]>([]);
  const [selectedSegment, setSelectedSegment] = useState("html"); // "html" or "shortcuts"
  const reportTemplatesApi = new TemplateReportsApi(apiConfiguration);

  const { token } = theme.useToken();

  const columns = [
    {
      title: "Report Template ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Professional ID",
      dataIndex: "professional_id",
      key: "professional_id",
    },
    {
      title: "Parent ID",
      dataIndex: "parent_id",
      key: "parent_id",
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: ReportTemplate) => (
        <Button
          type="primary"
          onClick={() => {
            setSelectedReportTemplate(record);
            setIsModalVisible(true);
          }}
        >
          Afficher
        </Button>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      const reportTemplateResponse: ApiResponse<ReportTemplateOutput> =
        await reportTemplatesApi.templateReportGetAllRaw({ items: 999 });
      if (!reportTemplateResponse.raw.ok) console.error(reportTemplateResponse);
      else {
        const reportTemplateOutput: ReportTemplateOutput = await reportTemplateResponse.raw.json();
        const reportTemplates: ReportTemplate[] = reportTemplateOutput.data;
        setReportTemplates(reportTemplates);
      }
    })();
  }, []);

  useEffect(() => {
    if (selectedReportTemplate) {
      const fetchShortcuts = async () => {
        const shortcutResponse: ApiResponse<any> = await reportTemplatesApi.templateReportShortcutGetAllRaw({
          templateId:selectedReportTemplate.id
        });
        if (shortcutResponse.raw.ok) {
          const shortcutData = await shortcutResponse.raw.json();
          setShortcuts(shortcutData.data);
        } else {
          console.error(shortcutResponse);
        }
      };
      fetchShortcuts();
    }
  }, [selectedReportTemplate]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Flex vertical gap={32} flex={1}>
      <Typography.Title>Report Templates</Typography.Title>
      <Flex
        style={{
          border: "1px solid " + token.colorBorder,
          borderRadius: 16,
          overflow: "hidden",
        }}
      >
        <Table style={{ width: "100%" }} pagination={{ pageSize: 16 }} columns={columns} dataSource={reportTemplates}></Table>
      </Flex>

      <Modal
        title="Détails"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closeIcon={<Button type="text" onClick={handleCancel}>×</Button>}
        width={800}
      >
        {selectedReportTemplate && (
          <div>
            {/* Segmented to switch between HTML content and Shortcuts */}
            <Segmented
              options={["html", "shortcuts"]}
              value={selectedSegment}
              onChange={setSelectedSegment}
              style={{ marginBottom: 16 }}
            />

            {selectedSegment === "html" ? (
              // Show HTML Content
              <Flex>
                {/* HTML content of the template */}
                <div style={{ flex: 1, paddingRight: 16 }}>
                  <Typography.Title level={4}>Generated Content</Typography.Title>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: new DOMParser()
                        .parseFromString(selectedReportTemplate.content, "text/html")
                        .body.innerHTML,
                    }}
                  />
                </div>
              </Flex>
            ) : (
              // Show Shortcuts
              <div>
                <Typography.Title level={4}>Shortcuts</Typography.Title>
                <ul>
                  {shortcuts?.length > 0 ? (
                    shortcuts.map((shortcut) => (
                      <li key={shortcut.id}>
                        <Typography.Text strong>{shortcut.title}</Typography.Text>: {shortcut.content}
                      </li>
                    ))
                  ) : (
                    <li>No shortcuts available.</li>
                  )}
                </ul>
              </div>
            )}
          </div>
        )}
      </Modal>
    </Flex>
  );
}
