"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMedicalHistory = instanceOfMedicalHistory;
exports.MedicalHistoryFromJSON = MedicalHistoryFromJSON;
exports.MedicalHistoryFromJSONTyped = MedicalHistoryFromJSONTyped;
exports.MedicalHistoryToJSON = MedicalHistoryToJSON;
/**
 * Check if a given object implements the MedicalHistory interface.
 */
function instanceOfMedicalHistory(value) {
    if (!('begin_at' in value) || value['begin_at'] === undefined)
        return false;
    if (!('comment' in value) || value['comment'] === undefined)
        return false;
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('created_by' in value) || value['created_by'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('history_type' in value) || value['history_type'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('is_important' in value) || value['is_important'] === undefined)
        return false;
    if (!('medical_record_id' in value) || value['medical_record_id'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    return true;
}
function MedicalHistoryFromJSON(json) {
    return MedicalHistoryFromJSONTyped(json, false);
}
function MedicalHistoryFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'begin_at': json['begin_at'],
        'comment': json['comment'],
        'content': json['content'],
        'created_at': json['created_at'],
        'created_by': json['created_by'],
        'deleted_at': json['deleted_at'],
        'history_type': json['history_type'],
        'id': json['id'],
        'is_important': json['is_important'],
        'medical_record_id': json['medical_record_id'],
        'updated_at': json['updated_at'],
    };
}
function MedicalHistoryToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'begin_at': value['begin_at'],
        'comment': value['comment'],
        'content': value['content'],
        'created_at': value['created_at'],
        'created_by': value['created_by'],
        'deleted_at': value['deleted_at'],
        'history_type': value['history_type'],
        'id': value['id'],
        'is_important': value['is_important'],
        'medical_record_id': value['medical_record_id'],
        'updated_at': value['updated_at'],
    };
}
