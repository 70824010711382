"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAgentInput = instanceOfAgentInput;
exports.AgentInputFromJSON = AgentInputFromJSON;
exports.AgentInputFromJSONTyped = AgentInputFromJSONTyped;
exports.AgentInputToJSON = AgentInputToJSON;
var PatientInformation_1 = require("./PatientInformation");
/**
 * Check if a given object implements the AgentInput interface.
 */
function instanceOfAgentInput(value) {
    return true;
}
function AgentInputFromJSON(json) {
    return AgentInputFromJSONTyped(json, false);
}
function AgentInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'conversation': json['conversation'] == null ? undefined : json['conversation'],
        'html_template': json['html_template'] == null ? undefined : json['html_template'],
        'patient_information': json['patient_information'] == null ? undefined : (0, PatientInformation_1.PatientInformationFromJSON)(json['patient_information']),
        'raw_template': json['raw_template'] == null ? undefined : json['raw_template'],
    };
}
function AgentInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'conversation': value['conversation'],
        'html_template': value['html_template'],
        'patient_information': (0, PatientInformation_1.PatientInformationToJSON)(value['patient_information']),
        'raw_template': value['raw_template'],
    };
}
