import React, { useContext } from "react";
import { Button, Flex, Select, Typography, theme } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEraser,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import FlowPannel from "./flowPannel/FlowPannel";
import { exampleTree } from "../../types/LLMPlayground/AutomationTree";
import { ReactFlowProvider } from "@xyflow/react";
import BottomToolbar from "./BottomToolbar";
import Variables from "./variables/Variables";
import { AppContext } from "../../app-state-manager/AppContext";
import useLLMPipeline from "../../hooks/llm-playground/useLLMPipeline";

export default function LLMPlayground() {
  const { token } = theme.useToken();
  const {
    appState: {
      LLMPlayground: { tree, variables },
    },
    dispatch,
  } = useContext(AppContext);
  
  const { launchPipeline } = useLLMPipeline();

  return (
    <ReactFlowProvider>
      <Flex vertical flex={1}>
        <Typography.Title>LLM Playground</Typography.Title>
        <Flex
          style={{
            borderRadius: 16,
            border: "1px solid " + token.colorBorder,
            flex: 1,

            flexGrow: 1,
            flexBasis: 0,
            flexShrink: 1,
            width: "100%",
            height: 800,
            backgroundColor: token.colorBgContainer,
          }}
        >
          <Flex vertical flex={1}>
            <Flex vertical flex={1}>
              <Flex
                style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 16 }}
              >
                <Typography.Title level={3}>Tree</Typography.Title>
              </Flex>
              <Flex flex={1}>
                <FlowPannel />
              </Flex>
            </Flex>
            <Flex style={{ borderTop: "1px solid " + token.colorBorder }}>
              <BottomToolbar />
              <Flex
                align="center"
                justify="center"
                style={{
                  borderLeft: "1px solid " + token.colorBorder,
                  padding: 16,
                }}
              >
                <Button
                  type="primary"
                  icon={<FontAwesomeIcon icon={faRocket} />}
                  onClick={async () => {await launchPipeline(tree, variables, dispatch)}}
                >
                  Démarrer la pipeline
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            vertical
            style={{ width: 278, borderLeft: "1px solid " + token.colorBorder }}
          >
            <Variables />
            <Flex
              style={{
                padding: 16,
                borderTop: "1px solid " + token.colorBorder,
              }}
              justify="center"
              align="center"
            >
              <Button
                danger
                icon={
                  <FontAwesomeIcon
                    icon={faEraser}
                    onClick={() =>
                      dispatch({ type: "@LLM_PLAYGROUND/VARIABLES/DELETE_ALL" })
                    }
                  />
                }
              >
                Effacer les variables
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ReactFlowProvider>
  );
}
