import {
  Configuration,
  ResponseContext,
  RequestContext,
} from "@thiana/api-thiana-client";
import { postMiddleware } from "./PostMiddleware";
import { preMiddleware } from "./PreMiddleware";

// API configuration
export const apiConfiguration = new Configuration({
  basePath: process.env.REACT_APP_URL_BACKAPP_CLIENT,
  middleware: [
    {
      // function called before the request
      pre: async (context: RequestContext) => {
        await preMiddleware(context);
      },
      // function called on the response
      post: async (context: ResponseContext) => {
        await postMiddleware(context);
      },
    },
  ],
});