"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfProfessionalInput = instanceOfProfessionalInput;
exports.ProfessionalInputFromJSON = ProfessionalInputFromJSON;
exports.ProfessionalInputFromJSONTyped = ProfessionalInputFromJSONTyped;
exports.ProfessionalInputToJSON = ProfessionalInputToJSON;
/**
 * Check if a given object implements the ProfessionalInput interface.
 */
function instanceOfProfessionalInput(value) {
    if (!('phone' in value) || value['phone'] === undefined)
        return false;
    if (!('user_id' in value) || value['user_id'] === undefined)
        return false;
    if (!('voice_usage_accepted' in value) || value['voice_usage_accepted'] === undefined)
        return false;
    return true;
}
function ProfessionalInputFromJSON(json) {
    return ProfessionalInputFromJSONTyped(json, false);
}
function ProfessionalInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'avatar': json['avatar'] == null ? undefined : json['avatar'],
        'phone': json['phone'],
        'school_certificate': json['school_certificate'] == null ? undefined : json['school_certificate'],
        'user_id': json['user_id'],
        'voice_usage_accepted': json['voice_usage_accepted'],
    };
}
function ProfessionalInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'avatar': value['avatar'],
        'phone': value['phone'],
        'school_certificate': value['school_certificate'],
        'user_id': value['user_id'],
        'voice_usage_accepted': value['voice_usage_accepted'],
    };
}
