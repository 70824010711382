"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfReportSnapshotInput = instanceOfReportSnapshotInput;
exports.ReportSnapshotInputFromJSON = ReportSnapshotInputFromJSON;
exports.ReportSnapshotInputFromJSONTyped = ReportSnapshotInputFromJSONTyped;
exports.ReportSnapshotInputToJSON = ReportSnapshotInputToJSON;
/**
 * Check if a given object implements the ReportSnapshotInput interface.
 */
function instanceOfReportSnapshotInput(value) {
    if (!('audio_path' in value) || value['audio_path'] === undefined)
        return false;
    if (!('generation' in value) || value['generation'] === undefined)
        return false;
    if (!('transcription' in value) || value['transcription'] === undefined)
        return false;
    return true;
}
function ReportSnapshotInputFromJSON(json) {
    return ReportSnapshotInputFromJSONTyped(json, false);
}
function ReportSnapshotInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'audio_path': json['audio_path'],
        'generation': json['generation'],
        'transcription': json['transcription'],
    };
}
function ReportSnapshotInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'audio_path': value['audio_path'],
        'generation': value['generation'],
        'transcription': value['transcription'],
    };
}
