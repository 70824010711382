"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMeOutput = instanceOfMeOutput;
exports.MeOutputFromJSON = MeOutputFromJSON;
exports.MeOutputFromJSONTyped = MeOutputFromJSONTyped;
exports.MeOutputToJSON = MeOutputToJSON;
var Me_1 = require("./Me");
/**
 * Check if a given object implements the MeOutput interface.
 */
function instanceOfMeOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
function MeOutputFromJSON(json) {
    return MeOutputFromJSONTyped(json, false);
}
function MeOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(Me_1.MeFromJSON)),
    };
}
function MeOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(Me_1.MeToJSON)),
    };
}
