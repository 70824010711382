"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAddressOutput = instanceOfAddressOutput;
exports.AddressOutputFromJSON = AddressOutputFromJSON;
exports.AddressOutputFromJSONTyped = AddressOutputFromJSONTyped;
exports.AddressOutputToJSON = AddressOutputToJSON;
var Address_1 = require("./Address");
var Metadata_1 = require("./Metadata");
/**
 * Check if a given object implements the AddressOutput interface.
 */
function instanceOfAddressOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
function AddressOutputFromJSON(json) {
    return AddressOutputFromJSONTyped(json, false);
}
function AddressOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(Address_1.AddressFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
function AddressOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(Address_1.AddressToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
