"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfConsultationInput = instanceOfConsultationInput;
exports.ConsultationInputFromJSON = ConsultationInputFromJSON;
exports.ConsultationInputFromJSONTyped = ConsultationInputFromJSONTyped;
exports.ConsultationInputToJSON = ConsultationInputToJSON;
/**
 * Check if a given object implements the ConsultationInput interface.
 */
function instanceOfConsultationInput(value) {
    if (!('begin_at' in value) || value['begin_at'] === undefined)
        return false;
    if (!('end_at' in value) || value['end_at'] === undefined)
        return false;
    if (!('medical_record_id' in value) || value['medical_record_id'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    return true;
}
function ConsultationInputFromJSON(json) {
    return ConsultationInputFromJSONTyped(json, false);
}
function ConsultationInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'begin_at': json['begin_at'],
        'end_at': json['end_at'],
        'medical_record_id': json['medical_record_id'],
        'professional_id': json['professional_id'],
    };
}
function ConsultationInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'begin_at': value['begin_at'],
        'end_at': value['end_at'],
        'medical_record_id': value['medical_record_id'],
        'professional_id': value['professional_id'],
    };
}
