import { faFile, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { User, UserOutput, UsersApi } from "@thiana/api-thiana-client";
import { DatePicker, Flex, theme, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { apiConfiguration } from "../../../api/Configuration";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { getDatesBetween, countCreatedBetween } from "../../../utils/dates";
import moment from "moment";
import dayjs from "dayjs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function LastUsersTable() {
  const { token } = theme.useToken();
  const [startDate, setStartDate] = useState("01/12/2024");
  const [endDate, setEndDate] = useState("10/01/2025");
  const [data, setData] = useState<ChartData<any> | undefined>(undefined);

  const userApi = new UsersApi(apiConfiguration);

  useEffect(() => {
    (async () => {
      const usersResponse = await userApi.userGetAllRaw({ items: 999 });
      if (!usersResponse.raw.ok) {
        console.error(usersResponse);
      } else {
        const usersOutput: UserOutput = await usersResponse.raw.json();
        const users: User[] = usersOutput.data;

        const data = {
          labels: getDatesBetween(startDate, endDate),
          datasets: [
            {
              label: "Nouveaux utilisateurs",
              data: countCreatedBetween(users, startDate, endDate),
              backgroundColor: token.colorPrimary,
              borderColor: token.colorPrimary,
              borderWidth: 1,
            },
          ],
        };
        setData(data);
      }
    })();
  }, [startDate, endDate]);

  return (
    <Flex
      flex={1}
      gap={32}
      vertical
      style={{
        backgroundColor: token.colorBgContainer,
        border: `1px solid ${token.colorBorder}`,
        borderRadius: 16,
        padding: 16,
        color: token.colorTextBase,
        boxSizing:"border-box"
      }}
    >
      <Flex justify="space-between">
        <Flex align="center" gap={4}>
          <FontAwesomeIcon icon={faUserGroup} />
          <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>
            Nombre d'utilisateurs
          </Typography.Text>
        </Flex>
        <Flex gap={4}>
          <DatePicker
            value={dayjs(startDate, "DD/MM/YYYY")}
            format="DD/MM/YYYY"
            onChange={(date) => {
              setStartDate(date.format("DD/MM/YYYY"));
            }}
            placeholder="Depuis"
          />
          <DatePicker
            value={dayjs(endDate, "DD/MM/YYYY")}
            format="DD/MM/YYYY"
            onChange={(date) => {
              setEndDate(date.format("DD/MM/YYYY"));
            }}
            placeholder="Jusqu'à"
          />
        </Flex>
      </Flex>
      {data ? (
        <Bar
          data={data}
          options={{
            responsive: true,
            plugins: { title: { display: false }, legend: { display: false } },
          }}
        />
      ) : null}
    </Flex>
  );
}
