import { CustomEdge } from "./custom-nodes/CustomEdge";
import { CustomNode } from "./custom-nodes/CustomNode";

export type AutomationTree = {
  id: string;
  nodes: CustomNode[];
  edges: CustomEdge[];
};

export const exampleTree: AutomationTree = {
  id: "000",
  nodes: [
    {
      id: "start",
      type: "start",
      draggable: true,
      data: {},
      position: { x: 0, y: 0 },
    },
    {
      id: "llm1",
      type: "llm",
      draggable: true,
      data: {
        name: "llm",
        status: "initial",
        model: "llama-3.1-70b",
        nodesToInclude: [],
        prompts: {
          system: "system prompt",
          user: "user prompt",
          assistant: "assistant result",
        },
        action: {
          type: "replace",
          location: "variable:0001",
          regex: false,
          textToReplace: "nothing",
        },
      },
      position: { x: 0, y: 0 },
    },
    {
      id: "llm2",
      type: "llm",
      draggable: true,
      data: {
        name: "llm",
        status: "initial",
        model: "llama-3.1-70b",
        nodesToInclude: ["llm1"],
        prompts: {
          system: "system prompt",
          user: "user prompt",
          assistant: "assistant result",
        },
        action: {
          type: "add",
          location: "variable:0001",
          regex: false,
          textToReplace: "nothing",
        },
      },
      position: { x: 0, y: 0 },
    },
    {
      id: "llm3",
      type: "llm",
      draggable: true,
      data: {
        name: "llm",
        status: "initial",
        model: "llama-3.1-70b",
        nodesToInclude: ["llm1", "llm2"],
        prompts: {
          system: "system prompt",
          user: "user prompt",
          assistant: "assistant result",
        },
        action: {
          type: "add",
          location: "variable:0001",
          regex: false,
          textToReplace: "nothing",
        },
      },
      position: { x: 0, y: 0 },
    },
    {
      id: "end",
      type: "end",
      draggable: true,
      data: {},
      position: { x: 0, y: 0 },
    },
  ],
  edges: [
    { id: "0", label: "", source: "start", target: "llm1" },
    { id: "1", label: "", source: "llm1", target: "llm2" },
    { id: "2", label: "", source: "llm2", target: "llm3" },
    { id: "3", label: "", source: "llm3", target: "end" },
  ],
};
