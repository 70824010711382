"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfChatbotOutput = instanceOfChatbotOutput;
exports.ChatbotOutputFromJSON = ChatbotOutputFromJSON;
exports.ChatbotOutputFromJSONTyped = ChatbotOutputFromJSONTyped;
exports.ChatbotOutputToJSON = ChatbotOutputToJSON;
var Metadata_1 = require("./Metadata");
var Chatbot_1 = require("./Chatbot");
/**
 * Check if a given object implements the ChatbotOutput interface.
 */
function instanceOfChatbotOutput(value) {
    if (!('data' in value) || value['data'] === undefined)
        return false;
    return true;
}
function ChatbotOutputFromJSON(json) {
    return ChatbotOutputFromJSONTyped(json, false);
}
function ChatbotOutputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'data': (json['data'].map(Chatbot_1.ChatbotFromJSON)),
        'metadata': json['metadata'] == null ? undefined : (0, Metadata_1.MetadataFromJSON)(json['metadata']),
    };
}
function ChatbotOutputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'data': (value['data'].map(Chatbot_1.ChatbotToJSON)),
        'metadata': (0, Metadata_1.MetadataToJSON)(value['metadata']),
    };
}
