"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfDocumentInput = instanceOfDocumentInput;
exports.DocumentInputFromJSON = DocumentInputFromJSON;
exports.DocumentInputFromJSONTyped = DocumentInputFromJSONTyped;
exports.DocumentInputToJSON = DocumentInputToJSON;
/**
 * Check if a given object implements the DocumentInput interface.
 */
function instanceOfDocumentInput(value) {
    return true;
}
function DocumentInputFromJSON(json) {
    return DocumentInputFromJSONTyped(json, false);
}
function DocumentInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'consultation_id': json['consultation_id'] == null ? undefined : json['consultation_id'],
        'content': json['content'] == null ? undefined : json['content'],
        'description': json['description'] == null ? undefined : json['description'],
        'file': json['file'] == null ? undefined : json['file'],
        'medical_record_id': json['medical_record_id'] == null ? undefined : json['medical_record_id'],
        'professional_id': json['professional_id'] == null ? undefined : json['professional_id'],
        'title': json['title'] == null ? undefined : json['title'],
        'version': json['version'] == null ? undefined : json['version'],
    };
}
function DocumentInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'consultation_id': value['consultation_id'],
        'content': value['content'],
        'description': value['description'],
        'file': value['file'],
        'medical_record_id': value['medical_record_id'],
        'professional_id': value['professional_id'],
        'title': value['title'],
        'version': value['version'],
    };
}
