import React, { useEffect, useState } from "react";
import { Flex, Table, theme, Typography } from "antd";
import {
  ApiResponse,
  Workspace,
  WorkspaceOutput,
  WorkspacesApi,
} from "@thiana/api-thiana-client";
import { apiConfiguration } from "../../api/Configuration";

export default function WorkspacesList() {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
  const workspacesApi = new WorkspacesApi(apiConfiguration);

  const { token } = theme.useToken();

  const columns = [
    {
      title: "Workspace Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address ID",
      dataIndex: "address_id",
      key: "address_id",
    },
    {
      title: "Logo ID",
      dataIndex: "logo_id",
      key: "logo_id",
    },
    {
      title: "Professional ID",
      dataIndex: "professional_id",
      key: "professional_id",
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
    {
      title: "Deleted At",
      dataIndex: "deleted_at",
      key: "deleted_at",
      render: (text: string) => (text ? new Date(text).toLocaleDateString("fr-FR") : "N/A"), // Gérer la valeur optionnelle
    },
  ];

  useEffect(() => {
    (async () => {
      const workspaceResponse: ApiResponse<any> =
        await workspacesApi.getAllWorkspacesRaw({ items: 999 });
      if (!workspaceResponse.raw.ok) console.error(workspaceResponse);
      else {
        const workspaceOutput: WorkspaceOutput = await workspaceResponse.raw.json();
        const workspaces: Workspace[] = workspaceOutput.data;
        setWorkspaces(workspaces);
      }
    })();
  }, []);

  return (
    <Flex vertical gap={32} flex={1}>
      <Typography.Title>Workspaces</Typography.Title>
      <Flex
        style={{
          border: "1px solid " + token.colorBorder,
          borderRadius: 16,
          overflow: "hidden",
        }}
      >
        <Table style={{ width: "100%" }} pagination={{ pageSize: 16 }} columns={columns} dataSource={workspaces}></Table>
      </Flex>
    </Flex>
  );
}
