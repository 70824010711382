import { Flex, Menu, theme, Typography } from "antd";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDashboard, faHandSparkles, faMagic, faTable, faWandSparkles } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface Props {
  children: any;
}

export default function Layout({ children }: Props) {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  return (
    <Flex style={{ width: "100vw", height: "100vh" }}>
      <Flex
        vertical
        style={{
          borderRight: "1px solid " + token.colorBorder,
          backgroundColor: token.colorBgContainer,
          height: "100vh",
          width: 192,
          padding: 8,
          boxSizing: "border-box",
        }}
      >
        <Typography.Title level={4}>Menu</Typography.Title>
        <Menu style={{ border: "none" }} mode="inline">
          <Menu.Item
            onClick={() => navigate("/")}
            icon={<FontAwesomeIcon icon={faDashboard} />}
          >
            Dashboard
          </Menu.Item>
          <Menu.SubMenu title="Tables" icon={<FontAwesomeIcon icon={faTable}/>}>
            <Menu.SubMenu title="Users">
              <Menu.Item onClick={() => navigate("/users")}>Users</Menu.Item>
              <Menu.Item onClick={() => navigate("/professionals")}>
                Professionals
              </Menu.Item>
              <Menu.Item onClick={() => navigate("/workspaces")}>
                Workspaces
              </Menu.Item>
              <Menu.Item onClick={() => navigate("/subscriptions")}>
                Subscription
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu title="Thiana Assistant">
              <Menu.Item onClick={() => navigate("/reports")}>
                Reports
              </Menu.Item>
              <Menu.Item onClick={() => navigate("/templates")}>
                Templates
              </Menu.Item>
              <Menu.Item onClick={() => navigate("/shortcuts")}>
                Shortcuts
              </Menu.Item>
            </Menu.SubMenu>
          </Menu.SubMenu>
          <Menu.Item
            onClick={() => navigate("/llm-playground")}
            icon={<FontAwesomeIcon icon={faWandSparkles} />}
          >
            LLM Playground
          </Menu.Item>
        </Menu>
      </Flex>
      <Flex
        flex={1}
        style={{ backgroundColor: token.colorBgBase, padding: 32 }}
      >
        {children}
      </Flex>
    </Flex>
  );
}
