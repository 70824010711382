"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfCustomerInput = instanceOfCustomerInput;
exports.CustomerInputFromJSON = CustomerInputFromJSON;
exports.CustomerInputFromJSONTyped = CustomerInputFromJSONTyped;
exports.CustomerInputToJSON = CustomerInputToJSON;
/**
 * Check if a given object implements the CustomerInput interface.
 */
function instanceOfCustomerInput(value) {
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('first_name' in value) || value['first_name'] === undefined)
        return false;
    if (!('last_name' in value) || value['last_name'] === undefined)
        return false;
    if (!('phone' in value) || value['phone'] === undefined)
        return false;
    if (!('workspace_id' in value) || value['workspace_id'] === undefined)
        return false;
    return true;
}
function CustomerInputFromJSON(json) {
    return CustomerInputFromJSONTyped(json, false);
}
function CustomerInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'email': json['email'],
        'first_name': json['first_name'],
        'last_name': json['last_name'],
        'phone': json['phone'],
        'workspace_id': json['workspace_id'],
    };
}
function CustomerInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'email': value['email'],
        'first_name': value['first_name'],
        'last_name': value['last_name'],
        'phone': value['phone'],
        'workspace_id': value['workspace_id'],
    };
}
