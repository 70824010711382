import React, { useEffect, useState } from "react";
import { Flex, Table, theme, Typography } from "antd";
import {
  ApiResponse,
  User,
  UserOutput,
  UsersApi,
} from "@thiana/api-thiana-client";
import { apiConfiguration } from "../../api/Configuration";

export default function Users() {
  const [users, setUsers] = useState<User[]>([]);
  const usersApi = new UsersApi(apiConfiguration);

  const { token } = theme.useToken();

  const columns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Birth Date",
      dataIndex: "birth_date",
      key: "birth_date",
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (text: boolean) => (text ? "Active" : "Inactive"), // Affichage sous forme textuelle
    },
    {
      title: "Professional ID",
      dataIndex: "professional_id",
      key: "professional_id",
      render: (text: string) => text || "N/A", // Gérer les valeurs optionnelles
    },
    // {
    //   title: "CGU Accepted At",
    //   dataIndex: "cgu_accepted_at",
    //   key: "cgu_accepted_at",
    //   render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    // },
  ];

  useEffect(() => {
    (async () => {
      const userResponse: ApiResponse<UserOutput> =
        await usersApi.userGetAllRaw({ items: 999 });
      if (!userResponse.raw.ok) console.error(userResponse);
      else {
        const userOutput: UserOutput = await userResponse.raw.json();
        const users: User[] = userOutput.data;
        setUsers(users);
      }
    })();
  }, []);

  return (
    <Flex vertical gap={32} flex={1}>
      <Typography.Title>Users</Typography.Title>
      <Flex
        style={{
          border: "1px solid " + token.colorBorder,
          borderRadius: 16,
          overflow: "hidden",
        }}
      >
        <Table style={{width:"100%"}} pagination={{ pageSize: 16 }} columns={columns} dataSource={users}></Table>
      </Flex>
    </Flex>
  );
}
