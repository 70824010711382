import {
  Button,
  CollapseProps,
  Divider,
  Dropdown,
  Flex,
  Tag,
  theme,
  Typography,
  Collapse,
} from "antd";
import { Handle, Position, useHandleConnections } from "@xyflow/react";
import { LLMNodeData } from "../../../types/LLMPlayground/custom-nodes/custom-node-data/LLMNodeData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { CSSProperties, useCallback, useState } from "react";
import LLMNodeEditionModal from "./components/LLMNodeEditionModal";

interface Props {
  id: string;
  data: LLMNodeData;
}

export default function LLMNode({ id, data }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const targetConnections = useHandleConnections({
    type: "target",
  });
  const sourceConnections = useHandleConnections({
    type: "source",
  });

  const { token } = theme.useToken();

  const getItems = useCallback(
    (data: LLMNodeData) => [
      {
        key: "user",
        label: "System",
        children: (
          <Typography.Paragraph>{data.prompts.system}</Typography.Paragraph>
        ),
        style: { border: "none" },
        forceRender:true
      },
      {
        key: "system",
        label: "User",
        children: (
          <Typography.Paragraph>{data.prompts.user}</Typography.Paragraph>
        ),
        style: { border: "none" },
        forceRender:true
      },
      {
        key: "assistant",
        label: "Assistant",
        children: (
          <Typography.Paragraph>{data.prompts.assistant}</Typography.Paragraph>
        ),
        style: { border: `1px solid ${token.colorBorder}`, borderRadius: 8 },
        forceRender:true
      },
    ],
    [token.colorBorder]
  );

  return (
    <div>
      <Handle
        position={Position.Top}
        type="target"
        isConnectable={targetConnections.length < 1}
      />
      <Flex
        vertical
        gap={4}
        style={{
          width: 635,
          border: `1px solid ${token.colorBorder}`,
          backgroundColor: token.colorBgBase,
          borderRadius: 16,
          padding: 16,
        }}
      >
        <Flex justify="space-between">
          <Flex gap={8}>
            <Typography.Text style={{ fontWeight: "bold", fontSize: 20 }}>
              LLM Node
            </Typography.Text>
          </Flex>
          <Tag>{data.status}</Tag>
          {/* <Dropdown trigger={["click"]}>
            <Button type="text" icon={<FontAwesomeIcon icon={faEllipsis} />} />
          </Dropdown> */}
        </Flex>
        <Flex flex={1}>
          <Flex gap={16} wrap>
            <Typography.Text>
              <b>Nom : </b>
              {data.name}
            </Typography.Text>
            <Typography.Text>
              <b>Modèle : </b>
              {data.model}
            </Typography.Text>
            <Typography.Text>
              <b>Noeuds à inclure dans l'historique : </b>
              {data.nodesToInclude.join(", ")}
            </Typography.Text>
          </Flex>
        </Flex>
        <Divider
          type="horizontal"
          style={{ margin: 0, backgroundColor: token.colorBorder }}
        />
        <Flex>
          <Collapse
            style={{ width: "100%", background: "none" }}
            bordered={false}
            items={getItems(data)}
          />
        </Flex>
        <Button type="link" onClick={() => setIsModalOpen(true)}>
          Modifier
        </Button>
      </Flex>
      <LLMNodeEditionModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        id={id}
        data={data}
      />
      <Handle
        position={Position.Bottom}
        type="source"
        isConnectable={sourceConnections.length < 1}
      />
    </div>
  );
}
