"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfLog = instanceOfLog;
exports.LogFromJSON = LogFromJSON;
exports.LogFromJSONTyped = LogFromJSONTyped;
exports.LogToJSON = LogToJSON;
/**
 * Check if a given object implements the Log interface.
 */
function instanceOfLog(value) {
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('jwt' in value) || value['jwt'] === undefined)
        return false;
    if (!('method' in value) || value['method'] === undefined)
        return false;
    if (!('url' in value) || value['url'] === undefined)
        return false;
    return true;
}
function LogFromJSON(json) {
    return LogFromJSONTyped(json, false);
}
function LogFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'created_at': json['created_at'],
        'id': json['id'],
        'jwt': json['jwt'],
        'method': json['method'],
        'url': json['url'],
    };
}
function LogToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'created_at': value['created_at'],
        'id': value['id'],
        'jwt': value['jwt'],
        'method': value['method'],
        'url': value['url'],
    };
}
