"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfSessionInput = instanceOfSessionInput;
exports.SessionInputFromJSON = SessionInputFromJSON;
exports.SessionInputFromJSONTyped = SessionInputFromJSONTyped;
exports.SessionInputToJSON = SessionInputToJSON;
var SessionItem_1 = require("./SessionItem");
/**
 * Check if a given object implements the SessionInput interface.
 */
function instanceOfSessionInput(value) {
    if (!('items' in value) || value['items'] === undefined)
        return false;
    return true;
}
function SessionInputFromJSON(json) {
    return SessionInputFromJSONTyped(json, false);
}
function SessionInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'items': (json['items'].map(SessionItem_1.SessionItemFromJSON)),
    };
}
function SessionInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'items': (value['items'].map(SessionItem_1.SessionItemToJSON)),
    };
}
