
import { Flex, theme, Typography } from "antd";
import {
  Handle,
  Position,
  useHandleConnections,
} from "@xyflow/react";

export default function StartNode() {
  const sourceConnections = useHandleConnections({
    type: "source",
  });
  const {token} = theme.useToken()
  return (
    <Flex
      vertical
      gap={4}
      justify="center"
      align="center"
      style={{
        border: `1px solid ${token.colorBorder}`,
        backgroundColor:token.colorBgBase,
        borderRadius: 16,
        paddingRight:32,
        paddingLeft:32,
      }}
    >
      <Typography.Title level={3}>DEBUT</Typography.Title>
      <Handle position={Position.Bottom} type="source" isConnectable={sourceConnections.length < 1} />
    </Flex>
  );
}
