import React, { useEffect, useState } from "react";
import { Avatar, Flex, Table, theme, Typography } from "antd";
import {
  ApiResponse,
  Professional,
  ProfessionalOutput,
  ProfessionalsApi,
} from "@thiana/api-thiana-client";
import { apiConfiguration } from "../../api/Configuration";

export default function ProfessionalsList() {
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const professionalsApi = new ProfessionalsApi(apiConfiguration);

  const { token } = theme.useToken();

  const columns = [
    {
      title: "Avatar",
      dataIndex: "avatar",
      key: "avatar",
      render: (text: string) => <Avatar src={text} alt="Avatar" style={{ width: 50, height: 50, borderRadius: "50%" }} />, 
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Workspace ID",
      dataIndex: "personal_workspace_id",
      key: "personal_workspace_id",
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
    {
      title: "Is Assistant Tutorial Displayed",
      dataIndex: "is_assistant_tutorial_displayed",
      key: "is_assistant_tutorial_displayed",
      render: (text: boolean) => (text ? "Yes" : "No"), // Affichage sous forme textuelle
    },
    {
      title: "Voice Usage Accepted At",
      dataIndex: "voice_usage_accepted_at",
      key: "voice_usage_accepted_at",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
    {
      title: "Deleted At",
      dataIndex: "deleted_at",
      key: "deleted_at",
      render: (text: string) => (text ? new Date(text).toLocaleDateString("fr-FR") : "N/A"), // Gérer la valeur optionnelle
    },
  ];

  useEffect(() => {
    (async () => {
      const professionalResponse: ApiResponse<any> =
        await professionalsApi.professionalGetAllRaw({ items: 999 });
      if (!professionalResponse.raw.ok) console.error(professionalResponse);
      else {
        const professionalOutput: ProfessionalOutput = await professionalResponse.raw.json();
        const professionals: Professional[] = professionalOutput.data;
        setProfessionals(professionals);
      }
    })();
  }, []);

  return (
    <Flex vertical gap={32} flex={1}>
      <Typography.Title>Professionals</Typography.Title>
      <Flex
        style={{
          border: "1px solid " + token.colorBorder,
          borderRadius: 16,
          overflow: "hidden",
        }}
      >
        <Table style={{ width: "100%" }} pagination={{ pageSize: 16 }} columns={columns} dataSource={professionals}></Table>
      </Flex>
    </Flex>
  );
}
