"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAddress = instanceOfAddress;
exports.AddressFromJSON = AddressFromJSON;
exports.AddressFromJSONTyped = AddressFromJSONTyped;
exports.AddressToJSON = AddressToJSON;
/**
 * Check if a given object implements the Address interface.
 */
function instanceOfAddress(value) {
    if (!('city' in value) || value['city'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('street' in value) || value['street'] === undefined)
        return false;
    if (!('street2' in value) || value['street2'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    if (!('zip_code' in value) || value['zip_code'] === undefined)
        return false;
    return true;
}
function AddressFromJSON(json) {
    return AddressFromJSONTyped(json, false);
}
function AddressFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'city': json['city'],
        'created_at': json['created_at'],
        'id': json['id'],
        'street': json['street'],
        'street2': json['street2'],
        'updated_at': json['updated_at'],
        'zip_code': json['zip_code'],
    };
}
function AddressToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'city': value['city'],
        'created_at': value['created_at'],
        'id': value['id'],
        'street': value['street'],
        'street2': value['street2'],
        'updated_at': value['updated_at'],
        'zip_code': value['zip_code'],
    };
}
