"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfAdministrativeProof = instanceOfAdministrativeProof;
exports.AdministrativeProofFromJSON = AdministrativeProofFromJSON;
exports.AdministrativeProofFromJSONTyped = AdministrativeProofFromJSONTyped;
exports.AdministrativeProofToJSON = AdministrativeProofToJSON;
/**
 * Check if a given object implements the AdministrativeProof interface.
 */
function instanceOfAdministrativeProof(value) {
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    return true;
}
function AdministrativeProofFromJSON(json) {
    return AdministrativeProofFromJSONTyped(json, false);
}
function AdministrativeProofFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'created_at': json['created_at'],
        'id': json['id'],
        'name': json['name'],
        'updated_at': json['updated_at'],
    };
}
function AdministrativeProofToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'created_at': value['created_at'],
        'id': value['id'],
        'name': value['name'],
        'updated_at': value['updated_at'],
    };
}
