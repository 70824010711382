import {
  faCheck,
  faCross,
  faFile,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ApiResponse,
  User,
  UserOutput,
  UsersApi,
} from "@thiana/api-thiana-client";
import { Button, Flex, Input, Table, theme, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { apiConfiguration } from "../../../api/Configuration";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useNavigate } from "react-router-dom";
import { defaultUUID } from "../../../types/uuid";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function LastUsersTable() {
  const [users, setUsers] = useState<User[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const navigate = useNavigate();

  const usersApi = new UsersApi(apiConfiguration);

  const { token } = theme.useToken();

  const columns = [
    {
      title: "Prénom",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Nom",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Birth Date",
      dataIndex: "birth_date",
      key: "birth_date",
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
    },

    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (value: boolean) =>
        value ? (
          <FontAwesomeIcon color={token.colorSuccess} icon={faCheck} />
        ) : (
          <FontAwesomeIcon color={token.colorError} icon={faCross} />
        ),
    },

    {
      title: "Professional",
      dataIndex: "professional_id",
      key: "professional_id",
      render: (text: string) =>
        text !== defaultUUID ? (
          <FontAwesomeIcon color={token.colorSuccess} icon={faCheck} />
        ) : (
          <FontAwesomeIcon color={token.colorError} icon={faCross} />
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Button type="primary" onClick={() => navigate(`/user/${record.id}`)}>
          Ouvrir
        </Button>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      const userResponse: ApiResponse<UserOutput> =
        await usersApi.userGetAllRaw({ items: 999 });
      if (!userResponse.raw.ok) console.error(userResponse);
      else {
        const userOutput: UserOutput = await userResponse.raw.json();
        const users: User[] = userOutput.data;
        setUsers(users);
      }
    })();
  }, []);

  return (
    <Flex
      flex={2}
      vertical
      gap={8}
      style={{
        backgroundColor: token.colorBgContainer,
        border: `1px solid ${token.colorBorder}`,
        borderRadius: 16,
        padding: 16,
        color: token.colorTextBase,
        boxSizing: "border-box",
      }}
    >
      <Flex align="center" gap={4}>
        <FontAwesomeIcon icon={faUserGroup} />
        <Typography.Text style={{ fontSize: 24, fontWeight: 600 }}>
          Derniers utilisateurs
        </Typography.Text>
      </Flex>
      <Input.Search
        placeholder="Chercher un utilisateur (par nom, prénom, email ou professional_id)"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Flex>
        <Table
          style={{
            width: "100%",
            borderRadius: 16,
            overflow: "hidden",
            border: `1px solid ${token.colorBorder}`,
            boxSizing: "border-box",
          }}
          scroll={{ y: 356 }}
          pagination={{ pageSize: 8 }}
          columns={columns}
          dataSource={users.filter((user) =>
            `${user.email} ${user.first_name} ${user.last_name} ${user.professional_id}`
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )}
        />
      </Flex>
    </Flex>
  );
}
