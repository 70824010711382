import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Report } from "@thiana/api-thiana-client";
import { Button, Flex, Modal, Table, theme, Typography } from "antd";
import React, { useState } from 'react'

interface Props {
    reports:Report[]
}

export default function LastReports({reports}:Props) {
    const { token } = theme.useToken();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedReport, setSelectedReport] = useState<Report | null>(null);
    const columns = [
        {
          title: "Report ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: "Title",
          dataIndex: "title",
          key: "title",
        },
        // {
        //   title: "Report Template ID",
        //   dataIndex: "report_template_id",
        //   key: "report_template_id",
        // },
        // {
        //   title: "Generation Date",
        //   dataIndex: "generation",
        //   key: "generation",
        //   render: (text: string) => {
        //     const parser = new DOMParser();
        //     const doc = parser.parseFromString(text, "text/html");
        //     return <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />;
        //   },
        // },
        {
          title: "Creation Date",
          dataIndex: "created_at",
          key: "created_at",
        },
        {
          title: "Last Updated",
          dataIndex: "updated_at",
          key: "updated_at",
        },
        // {
        //   title: "Transcription",
        //   dataIndex: "transcription",
        //   key: "transcription",
        //   render: (text: string) => <div>{text.length > 100 ? text.substring(0, 100) + "..." : text}</div>, // Limiter l'affichage du texte
        // },
        {
          title: "Actions",
          key: "actions",
          render: (text: string, record: Report) => (
            <Button
              type="text"
              onClick={() => {
                setSelectedReport(record);
                setIsModalVisible(true);
              }}
            >
              Afficher
            </Button>
          ),
        },
      ];

      const handleCancel = () => {
        setIsModalVisible(false);
      };

  return (
    <Flex
    flex={1}
    style={{
      border: `1px solid ${token.colorBorder}`,
      backgroundColor: token.colorBgContainer,
      padding: 16,
      borderRadius: 16,
    }}
    vertical
  >
    <Flex align="center" style={{ color: token.colorTextBase }} gap={4}>
      <FontAwesomeIcon icon={faUser} />
      <Typography.Text style={{ fontSize: 24, fontWeight: 600 }}>
        Derniers Reports
      </Typography.Text>
    </Flex>
    <Flex
        style={{
          border: "1px solid " + token.colorBorder,
          borderRadius: 16,
        }}
      >
        <Table
          style={{ width: "100%", borderRadius: 16 }}
          pagination={{ pageSize: 16 }}
          columns={columns}
          dataSource={reports}
          scroll={{ y: 700 }}
        ></Table>
      </Flex>
      <Modal
        title="Détails"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closeIcon={
          <Button type="text" onClick={handleCancel}>
            ×
          </Button>
        }
        width={800}
      >
        {selectedReport && (
          <Flex>
            {/* Transcription à gauche */}
            <div style={{ flex: 1, paddingRight: 16 }}>
              <Typography.Title level={4}>Transcription</Typography.Title>
              <div>{selectedReport.transcription}</div>
            </div>

            {/* Generation à droite */}
            <div style={{ flex: 1 }}>
              <Typography.Title level={4}>Generation</Typography.Title>
              <div
                dangerouslySetInnerHTML={{
                  __html: new DOMParser().parseFromString(
                    selectedReport.generation,
                    "text/html"
                  ).body.innerHTML,
                }}
              />
            </div>
          </Flex>
        )}
      </Modal>
    </Flex>
  )
}
