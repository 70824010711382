"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfConsultation = instanceOfConsultation;
exports.ConsultationFromJSON = ConsultationFromJSON;
exports.ConsultationFromJSONTyped = ConsultationFromJSONTyped;
exports.ConsultationToJSON = ConsultationToJSON;
/**
 * Check if a given object implements the Consultation interface.
 */
function instanceOfConsultation(value) {
    if (!('begin_at' in value) || value['begin_at'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('created_by' in value) || value['created_by'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('end_at' in value) || value['end_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('medical_record_id' in value) || value['medical_record_id'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    if (!('workspace_id' in value) || value['workspace_id'] === undefined)
        return false;
    return true;
}
function ConsultationFromJSON(json) {
    return ConsultationFromJSONTyped(json, false);
}
function ConsultationFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'begin_at': json['begin_at'],
        'created_at': json['created_at'],
        'created_by': json['created_by'],
        'deleted_at': json['deleted_at'],
        'end_at': json['end_at'],
        'id': json['id'],
        'medical_record_id': json['medical_record_id'],
        'professional_id': json['professional_id'],
        'updated_at': json['updated_at'],
        'workspace_id': json['workspace_id'],
    };
}
function ConsultationToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'begin_at': value['begin_at'],
        'created_at': value['created_at'],
        'created_by': value['created_by'],
        'deleted_at': value['deleted_at'],
        'end_at': value['end_at'],
        'id': value['id'],
        'medical_record_id': value['medical_record_id'],
        'professional_id': value['professional_id'],
        'updated_at': value['updated_at'],
        'workspace_id': value['workspace_id'],
    };
}
