"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfReportProcessing = instanceOfReportProcessing;
exports.ReportProcessingFromJSON = ReportProcessingFromJSON;
exports.ReportProcessingFromJSONTyped = ReportProcessingFromJSONTyped;
exports.ReportProcessingToJSON = ReportProcessingToJSON;
/**
 * Check if a given object implements the ReportProcessing interface.
 */
function instanceOfReportProcessing(value) {
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('report_id' in value) || value['report_id'] === undefined)
        return false;
    return true;
}
function ReportProcessingFromJSON(json) {
    return ReportProcessingFromJSONTyped(json, false);
}
function ReportProcessingFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'content': json['content'],
        'created_at': json['created_at'],
        'id': json['id'],
        'report_id': json['report_id'],
    };
}
function ReportProcessingToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'content': value['content'],
        'created_at': value['created_at'],
        'id': value['id'],
        'report_id': value['report_id'],
    };
}
