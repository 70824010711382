import React, { useEffect, useState } from "react";
import { Flex, Table, theme, Typography } from "antd";
import {
  ApiResponse,
  Subscription,
  SubscriptionOutput,
PaymentsApi
} from "@thiana/api-thiana-client";
import { apiConfiguration } from "../../api/Configuration";

export default function SubscriptionsList() {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const paymentsApi = new PaymentsApi(apiConfiguration);

  const { token } = theme.useToken();

  const columns = [
    {
      title: "Subscription ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer ID",
      dataIndex: "customer_id",
      key: "customer_id",
    },
    {
      title: "External ID",
      dataIndex: "external_id",
      key: "external_id",
    },
    {
      title: "Payment Level",
      dataIndex: "payment_level",
      key: "payment_level",
    },
    {
      title: "Scope ID",
      dataIndex: "scope_id",
      key: "scope_id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Trial Until",
      dataIndex: "trial_until",
      key: "trial_until",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
    {
      title: "Checked Until",
      dataIndex: "checked_until",
      key: "checked_until",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
    {
      title: "Creation Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
    {
      title: "Last Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text: string) => new Date(text).toLocaleDateString("fr-FR"), // Formatage de la date
    },
  ];

  useEffect(() => {
    (async () => {
      const subscriptionResponse: ApiResponse<SubscriptionOutput> =
        await paymentsApi.subscriptionGetAllRaw({ items: 999 });
      if (!subscriptionResponse.raw.ok) console.error(subscriptionResponse);
      else {
        const subscriptionOutput: SubscriptionOutput = await subscriptionResponse.raw.json();
        const subscriptions: Subscription[] = subscriptionOutput.data;
        setSubscriptions(subscriptions);
      }
    })();
  }, []);

  return (
    <Flex vertical gap={32} flex={1}>
      <Typography.Title>Subscriptions</Typography.Title>
      <Flex
        style={{
          border: "1px solid " + token.colorBorder,
          borderRadius: 16,
          overflow: "hidden",
        }}
      >
        <Table style={{ width: "100%" }} pagination={{ pageSize: 16 }} columns={columns} dataSource={subscriptions}></Table>
      </Flex>
    </Flex>
  );
}
