"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStudent = instanceOfStudent;
exports.StudentFromJSON = StudentFromJSON;
exports.StudentFromJSONTyped = StudentFromJSONTyped;
exports.StudentToJSON = StudentToJSON;
/**
 * Check if a given object implements the Student interface.
 */
function instanceOfStudent(value) {
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('deleted_at' in value) || value['deleted_at'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('speciality' in value) || value['speciality'] === undefined)
        return false;
    if (!('status' in value) || value['status'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    if (!('year_cycle' in value) || value['year_cycle'] === undefined)
        return false;
    return true;
}
function StudentFromJSON(json) {
    return StudentFromJSONTyped(json, false);
}
function StudentFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'created_at': json['created_at'],
        'deleted_at': json['deleted_at'],
        'id': json['id'],
        'professional_id': json['professional_id'],
        'speciality': json['speciality'],
        'status': json['status'],
        'updated_at': json['updated_at'],
        'year_cycle': json['year_cycle'],
    };
}
function StudentToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'created_at': value['created_at'],
        'deleted_at': value['deleted_at'],
        'id': value['id'],
        'professional_id': value['professional_id'],
        'speciality': value['speciality'],
        'status': value['status'],
        'updated_at': value['updated_at'],
        'year_cycle': value['year_cycle'],
    };
}
