import { faDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Flex, MenuProps, Typography } from "antd";
import React, { useContext } from "react";
import { AppContext } from "../../app-state-manager/AppContext";
import { CustomNode } from "../../types/LLMPlayground/custom-nodes/CustomNode";
import { LLMNodeData } from "../../types/LLMPlayground/custom-nodes/custom-node-data/LLMNodeData";
import { v4 as uuidv4 } from "uuid";
import { Node } from "@xyflow/react";

export default function BottomToolbar() {
  const {
    appState: {
      LLMPlayground: { status, tree },
    },
    dispatch,
  } = useContext(AppContext);

  const containsNode = (
    type: "start" | "end",
    nodes: CustomNode[]
  ): boolean => {
    return nodes.some((node) => node.type === type);
  };

  const items: MenuProps["items"] = [
    {
      key: "start",
      label: (
        <Typography.Text onClick={() => addNode("start")}>
          Start Node
        </Typography.Text>
      ),
      disabled: containsNode("start", tree.nodes),
    },
    {
      key: "llm",
      label: (
        <Typography.Text onClick={() => addNode("llm")}>
          LLM Node
        </Typography.Text>
      ),
    },
    {
      key: "end",
      label: (
        <Typography.Text onClick={() => addNode("end")}>
          End Node
        </Typography.Text>
      ),
      disabled: containsNode("end", tree.nodes),
    },
  ];

  const addNode = (type: "start" | "end" | "llm") => {
    switch (type) {
      case "start":
        dispatch({
          type: "@LLM_PLAYGROUND/ADD_NODE",
          payload: {
            id: "start",
            type: "start",
            draggable: true,
            position: { x: 0, y: 0 },
          } as CustomNode,
        });
        break;
      case "end":
        dispatch({
          type: "@LLM_PLAYGROUND/ADD_NODE",
          payload: {
            id: "end",
            type: "end",
            draggable: true,
            position: { x: 0, y: 0 },
          } as CustomNode,
        });

        break;
      case "llm":
        dispatch({
          type: "@LLM_PLAYGROUND/ADD_NODE",
          payload: {
            id: uuidv4(),
            draggable: true,
            position: { x: 0, y: 0 },
            type: "llm",
            data: {
              name: "Nom",
              model: "llama-3,1-8b",
              nodesToInclude: [],
              prompts: { system: "", user: "", assistant: "" },
              status: "initial",
              action:{
                type:"add",
                location: "variable:variable1",
                regex: false,
                textToReplace: "nothing"
              }
            } as LLMNodeData,
          } as CustomNode,
        });

        break;
      default:
        break;
    }
  };

  return (
    <Flex flex={1} style={{ padding: 16 }} justify="space-between">
      <Dropdown menu={{ items }}>
        <Button icon={<FontAwesomeIcon icon={faPlus} />}>Nouveau</Button>
      </Dropdown>
      <Button type="text" icon={<FontAwesomeIcon icon={faDownload} />}>
        Télécharger
      </Button>
    </Flex>
  );
}
