"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfReport = instanceOfReport;
exports.ReportFromJSON = ReportFromJSON;
exports.ReportFromJSONTyped = ReportFromJSONTyped;
exports.ReportToJSON = ReportToJSON;
var Transcription_1 = require("./Transcription");
var ReportFeedback_1 = require("./ReportFeedback");
var ReportProcessing_1 = require("./ReportProcessing");
/**
 * Check if a given object implements the Report interface.
 */
function instanceOfReport(value) {
    if (!('created_at' in value) || value['created_at'] === undefined)
        return false;
    if (!('feedbacks' in value) || value['feedbacks'] === undefined)
        return false;
    if (!('generation' in value) || value['generation'] === undefined)
        return false;
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('professional_id' in value) || value['professional_id'] === undefined)
        return false;
    if (!('report_template_id' in value) || value['report_template_id'] === undefined)
        return false;
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('transcription' in value) || value['transcription'] === undefined)
        return false;
    if (!('updated_at' in value) || value['updated_at'] === undefined)
        return false;
    return true;
}
function ReportFromJSON(json) {
    return ReportFromJSONTyped(json, false);
}
function ReportFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'created_at': json['created_at'],
        'feedbacks': (json['feedbacks'].map(ReportFeedback_1.ReportFeedbackFromJSON)),
        'generation': json['generation'],
        'id': json['id'],
        'processings': json['processings'] == null ? undefined : (json['processings'].map(ReportProcessing_1.ReportProcessingFromJSON)),
        'professional_id': json['professional_id'],
        'report_template_id': json['report_template_id'],
        'title': json['title'],
        'transcription': json['transcription'],
        'transcriptions': json['transcriptions'] == null ? undefined : (json['transcriptions'].map(Transcription_1.TranscriptionFromJSON)),
        'updated_at': json['updated_at'],
    };
}
function ReportToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'created_at': value['created_at'],
        'feedbacks': (value['feedbacks'].map(ReportFeedback_1.ReportFeedbackToJSON)),
        'generation': value['generation'],
        'id': value['id'],
        'processings': value['processings'] == null ? undefined : (value['processings'].map(ReportProcessing_1.ReportProcessingToJSON)),
        'professional_id': value['professional_id'],
        'report_template_id': value['report_template_id'],
        'title': value['title'],
        'transcription': value['transcription'],
        'transcriptions': value['transcriptions'] == null ? undefined : (value['transcriptions'].map(Transcription_1.TranscriptionToJSON)),
        'updated_at': value['updated_at'],
    };
}
