import { Report, User } from "@thiana/api-thiana-client";

export function getDatesBetween(startDate: string, endDate: string): string[] {
    const formatDate = (date: Date): string =>
      `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
  
    const start = new Date(startDate.split('/').reverse().join('-'));
    const end = new Date(endDate.split('/').reverse().join('-'));
    
    if (start > end) {
      throw new Error('La date de début doit être antérieure ou égale à la date de fin.');
    }
  
    const dates: string[] = [];
    let currentDate = start;
  
    while (currentDate <= end) {
      dates.push(formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1); // Passer au jour suivant
    }
  
    return dates;
  }

  export function countCreatedBetween(
    data: User[] | Report[],
    startDate: string,
    endDate: string
  ): number[] {
    const formatDate = (date: Date): string =>
      `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
  
    const parseDate = (dateString: string): Date => {
      const [day, month, year] = dateString.split('/');
      return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    };
  
    const start = parseDate(startDate);
    const end = parseDate(endDate);
  
    if (start > end) {
      throw new Error('La date de début doit être antérieure ou égale à la date de fin.');
    }
  
    // Créer la liste des dates entre startDate et endDate
    const dates: string[] = [];
    let currentDate = start;
  
    while (currentDate <= end) {
      dates.push(formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    // Initialiser un compteur par date
    const counts: { [key: string]: number } = dates.reduce((acc, date) => {
      acc[date] = 0;
      return acc;
    }, {} as { [key: string]: number });
  
    // Compter les utilisateurs créés par date
    data.forEach((user) => {
      const createdDate = user.created_at.split(' ')[0]; // Extraire la partie "DD/MM/YYYY"
      if (counts[createdDate] !== undefined) {
        counts[createdDate]++;
      }
    });
  
    // Retourner un tableau de nombres correspondant aux comptes par date
    return dates.map((date) => counts[date]);
  }