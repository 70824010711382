import { createContext, Dispatch } from "react";
import { Action } from "./ActionTypes";
import { AppState, initialAppState } from "./AppState";

interface AppContextProps {
  appState: AppState;
  dispatch: Dispatch<Action>;
}

export const AppContext = createContext<AppContextProps>({
  appState: initialAppState,
  dispatch: () => {},
});
