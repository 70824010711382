"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTranscriptionStreamInput = instanceOfTranscriptionStreamInput;
exports.TranscriptionStreamInputFromJSON = TranscriptionStreamInputFromJSON;
exports.TranscriptionStreamInputFromJSONTyped = TranscriptionStreamInputFromJSONTyped;
exports.TranscriptionStreamInputToJSON = TranscriptionStreamInputToJSON;
var TranscriptionStreamMetadata_1 = require("./TranscriptionStreamMetadata");
/**
 * Check if a given object implements the TranscriptionStreamInput interface.
 */
function instanceOfTranscriptionStreamInput(value) {
    if (!('state' in value) || value['state'] === undefined)
        return false;
    return true;
}
function TranscriptionStreamInputFromJSON(json) {
    return TranscriptionStreamInputFromJSONTyped(json, false);
}
function TranscriptionStreamInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'audio': json['audio'] == null ? undefined : json['audio'],
        'metadata': json['metadata'] == null ? undefined : (0, TranscriptionStreamMetadata_1.TranscriptionStreamMetadataFromJSON)(json['metadata']),
        'state': json['state'],
        'transcription_id': json['transcription_id'] == null ? undefined : json['transcription_id'],
    };
}
function TranscriptionStreamInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'audio': value['audio'],
        'metadata': (0, TranscriptionStreamMetadata_1.TranscriptionStreamMetadataToJSON)(value['metadata']),
        'state': value['state'],
        'transcription_id': value['transcription_id'],
    };
}
