"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Thiana API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfMedicalHistoryInput = instanceOfMedicalHistoryInput;
exports.MedicalHistoryInputFromJSON = MedicalHistoryInputFromJSON;
exports.MedicalHistoryInputFromJSONTyped = MedicalHistoryInputFromJSONTyped;
exports.MedicalHistoryInputToJSON = MedicalHistoryInputToJSON;
/**
 * Check if a given object implements the MedicalHistoryInput interface.
 */
function instanceOfMedicalHistoryInput(value) {
    if (!('begin_at' in value) || value['begin_at'] === undefined)
        return false;
    if (!('comment' in value) || value['comment'] === undefined)
        return false;
    if (!('content' in value) || value['content'] === undefined)
        return false;
    if (!('history_type' in value) || value['history_type'] === undefined)
        return false;
    if (!('is_important' in value) || value['is_important'] === undefined)
        return false;
    if (!('medical_record_id' in value) || value['medical_record_id'] === undefined)
        return false;
    return true;
}
function MedicalHistoryInputFromJSON(json) {
    return MedicalHistoryInputFromJSONTyped(json, false);
}
function MedicalHistoryInputFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'begin_at': json['begin_at'],
        'comment': json['comment'],
        'content': json['content'],
        'history_type': json['history_type'],
        'is_important': json['is_important'],
        'medical_record_id': json['medical_record_id'],
    };
}
function MedicalHistoryInputToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'begin_at': value['begin_at'],
        'comment': value['comment'],
        'content': value['content'],
        'history_type': value['history_type'],
        'is_important': value['is_important'],
        'medical_record_id': value['medical_record_id'],
    };
}
