import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Flex, Input, Typography } from "antd";
import React, { useContext } from "react";
import { AppContext } from "../../../app-state-manager/AppContext";
import { Variable } from "../../../types/LLMPlayground/Variable";
import VariableListItem from "./VariableListItem";

export default function Variables() {
  const {
    appState: {
      LLMPlayground: { variables },
    },
  } = useContext(AppContext);
  return (
    <Flex
      flex={1}
      style={{
        paddingTop: 16,
        paddingLeft: 16,
        paddingRight: 16,
        height:700,
        flexGrow: 1,
        flexBasis: 0,
        flexShrink: 1,
      }}
      vertical
    >
      <Flex justify="space-between" align="center">
        <Flex>
          <Typography.Text style={{ fontSize: 16, fontWeight: "bold" }}>
            Variables
          </Typography.Text>
        </Flex>
        <Flex>
          <Button type="text" icon={<FontAwesomeIcon icon={faPlus} />}>
            Nouvelle variable
          </Button>
        </Flex>
      </Flex>
      <Flex
        flex={1}
        vertical
        gap={4}
        style={{
          overflowY: "scroll",
          flexGrow: 1,
          flexBasis: 0,
          flexShrink: 1,
        }}
      >
        {variables.map((variable: Variable) => {
          return <VariableListItem variable={variable} key={variable.id} />;
        })}
      </Flex>
    </Flex>
  );
}
