import {
  Flex,
  Input,
  Modal,
  Select,
  Tag,
  Typography,
  Checkbox,
  Divider,
  theme,
  Button,
  Radio,
  Switch,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { LLMNodeData } from "../../../../types/LLMPlayground/custom-nodes/custom-node-data/LLMNodeData";
import { AppContext } from "../../../../app-state-manager/AppContext";
import { CustomNode } from "../../../../types/LLMPlayground/custom-nodes/CustomNode";
import { findPrecedingLLMNodes } from "../function/findPrecedingLLMNodes";
import { Variable } from "../../../../types/LLMPlayground/Variable";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id: string;
  data: LLMNodeData;
}

export default function LLMNodeEditionModal({
  isModalOpen,
  setIsModalOpen,
  id,
  data,
}: Props) {
  const [name, setName] = useState(data.name);
  const [model, setModel] = useState(data.model);
  const {
    appState: {
      LLMPlayground: { tree, variables },
    },
    dispatch,
  } = useContext(AppContext);
  const [nodesToInclude, setNodesToInclude] = useState<string[]>(
    data.nodesToInclude
  );
  const [systemPrompt, setSystemPrompt] = useState(data.prompts.system);
  const [userPrompt, setUserPrompt] = useState(data.prompts.user);
  const [assistantPrompt, setAssistantPrompt] = useState(
    data.prompts.assistant
  );
  const [actionType, setActionType] = useState<"add" | "replace">(
    data.action.type
  );
  const [location, setLocation] = useState<
    | `${"variable" | "node"}:${string}:${"system" | "user" | "assistant"}`
    | `${"variable" | "node"}:${string}`
    | undefined
  >(data.action.location);
  const [isRegex, setIsRegex] = useState<boolean>(data.action.regex);
  const [textToReplace, setTextToReplace] = useState<string | RegExp>(
    data.action.textToReplace
  );

  const { token } = theme.useToken();

  const saveNode = () => {
    let updatedNode: CustomNode = {
      ...tree.nodes.find((node) => node.id === id),
    } as CustomNode;
    if (updatedNode) {
      (updatedNode.data as LLMNodeData).name = name;
      (updatedNode.data as LLMNodeData).model = model;
      (updatedNode.data as LLMNodeData).nodesToInclude = nodesToInclude;
      (updatedNode.data as LLMNodeData).prompts.system = systemPrompt;
      (updatedNode.data as LLMNodeData).prompts.user = userPrompt;
      (updatedNode.data as LLMNodeData).prompts.assistant = assistantPrompt;
      (updatedNode.data as LLMNodeData).action.type = actionType;
      (updatedNode.data as LLMNodeData).action.regex = isRegex;
      (updatedNode.data as LLMNodeData).action.textToReplace = textToReplace;
      (updatedNode.data as LLMNodeData).action.location = location as
        | `${"variable" | "node"}:${string}:${"system" | "user" | "assistant"}`
        | `${"variable" | "node"}:${string}`;
      console.log(location);
      dispatch({ type: "@LLM_PLAYGROUND/UPDATE_NODE", payload: updatedNode });
    }
    setIsModalOpen(false);
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      onClose={() => setIsModalOpen(false)}
      width={635}
      onOk={saveNode}
    >
      <Flex flex={1} vertical gap={8}>
        {/* header */}
        <Flex align="center" gap={4}>
          <Typography.Text style={{ fontSize: 20, fontWeight: "bold" }}>
            LLM Node
          </Typography.Text>
          <div>
            <Tag>{data.status}</Tag>
          </div>
        </Flex>
        {/* first line */}
        <Flex gap={8}>
          <Flex justify="center" align="center" gap={4}>
            <Flex>
              <Typography.Text>
                <b>Nom : </b>
              </Typography.Text>
            </Flex>
            <Flex>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </Flex>
          </Flex>
          <Flex justify="center" align="center" gap={4}>
            <Typography.Text>
              <b>Modèle : </b>
            </Typography.Text>
            <Select
              value={model}
              onChange={(value) => setModel(value)}
              options={[{ value: "llama-3.1-70b" }, { value: "llama-3.1-8b" }]}
            />
          </Flex>
        </Flex>
        {/* Nodes to include */}
        <Flex vertical>
          <Flex gap={8} align="center" justify="space-between">
            <Typography.Text>
              <b>Noeds à include dans l'historique</b>
            </Typography.Text>
            <Flex>
              <Button
                type="text"
                onClick={() =>
                  setNodesToInclude(
                    tree.nodes
                      .filter((node) => node.type === "llm")
                      .map((node) => node.id)
                  )
                }
              >
                Tout inclure
              </Button>
              <Button type="text" onClick={() => setNodesToInclude([])}>
                Tout excluse
              </Button>
            </Flex>
          </Flex>
          <Flex wrap gap={8} vertical>
            {findPrecedingLLMNodes(id, tree).map((node) => {
              return (
                <Flex gap={4}>
                  <Checkbox
                    checked={nodesToInclude.some((id) => id === node.id)}
                  />{" "}
                  <Typography.Text>
                    {(node.data as LLMNodeData).name}
                  </Typography.Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Divider
          type="horizontal"
          style={{ backgroundColor: token.colorBorder, margin: 0 }}
        />
        {/* System Prompt */}
        <Flex vertical>
          <Typography.Text style={{ fontSize: 24 }}>
            System Prompt
          </Typography.Text>
          <Input.TextArea
            value={systemPrompt}
            onChange={(e) => setSystemPrompt(e.target.value)}
          />
        </Flex>
        {/* User Prompt */}
        <Flex vertical>
          <Typography.Text style={{ fontSize: 24 }}>
            User Prompt
          </Typography.Text>
          <Input.TextArea
            value={userPrompt}
            onChange={(e) => setUserPrompt(e.target.value)}
          />
        </Flex>
        {/* Assistant Prompt */}
        <Flex
          vertical
          style={{
            padding: 8,
            border: `1px solid ${token.colorBorder}`,
            borderRadius: 8,
          }}
        >
          <Typography.Text style={{ fontSize: 24 }}>
            Assistant Prompt
          </Typography.Text>
          <Input.TextArea
            value={assistantPrompt}
            onChange={(e) => setAssistantPrompt(e.target.value)}
          />
        </Flex>
        <Divider
          type="horizontal"
          style={{ backgroundColor: token.colorBorder, margin: 0 }}
        />
        <Flex flex={1} vertical gap={8}>
          <Flex vertical>
            <Typography.Text style={{ fontSize: 16, fontWeight: "bold" }}>
              Post traitement :
            </Typography.Text>
            <Radio.Group
              onChange={(e) =>
                setActionType(e.target.value as "replace" | "add")
              }
              value={actionType}
            >
              <Radio value={"add"}>Ajouter</Radio>
              <Radio value={"replace"}>Remplacer</Radio>
            </Radio.Group>
          </Flex>
          <Flex vertical>
            <Typography.Text style={{ fontSize: 16, fontWeight: "bold" }}>
              Où {actionType === "add" ? "ajouter" : "remplacer"} :
            </Typography.Text>
            <Select
              style={{ width: "100%" }}
              onSelect={(_, option) => {
                console.log(option);
                setLocation(
                  option.value as
                    | `${"variable" | "node"}:${string}:${
                        | "system"
                        | "user"
                        | "assistant"}`
                    | `${"variable" | "node"}:${string}`
                );
              }}
              value={location}
              options={[
                ...tree.nodes
                  ?.filter((node: CustomNode) => node.type === "llm")
                  .flatMap((node: CustomNode) =>
                    ["system", "user", "assistant"].map(
                      (promptType: string) => ({
                        value: `node:${node.id}:${promptType}`,
                        label: `${promptType} prompt de ${node.data.name}`,
                      })
                    )
                  ),
                ...variables.map((variable: Variable) => ({
                  value: `variable:${variable.id}`,
                  label: `variable "${variable.name}"`,
                })),
              ]}
            />
          </Flex>
          {actionType === "replace" ? (
            <Flex vertical>
              <Typography.Text style={{ fontSize: 16, fontWeight: "bold" }}>
                Quoi remplacer :
              </Typography.Text>
              <Flex align="center" gap={4}>
                <Input
                  value={textToReplace as string}
                  onChange={(e) =>
                    setTextToReplace(e.target.value as string | RegExp)
                  }
                />
                <Switch
                  value={isRegex}
                  onChange={(checked) => setIsRegex(checked)}
                />
                REGEX
              </Flex>
            </Flex>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
}
