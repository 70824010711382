import { faFile, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Flex, Row, theme, Typography } from "antd";
import React from "react";
import UserGraph from "./components/UserGraph";
import ReportsGraph from "./components/ReportsGraph";
import LastUsersTable from "./components/LastUsersTable";

export default function Dashboard() {
  const { token } = theme.useToken();

  return (
    <Flex vertical>
      <Flex>
        <Typography.Title>Dashboard</Typography.Title>
      </Flex>
      <Flex vertical gap={8}>

      <Flex flex={1} gap={8}>
        <Flex>
          {/* User Graph */}
          <UserGraph />
        </Flex>
        <Flex>
          {/* Reports Graph */}
          <ReportsGraph />
        </Flex>
      </Flex>

      {/* Last user table */}
      <Flex vertical flex={3}>
        <LastUsersTable />
      </Flex>
      </Flex>
    </Flex>
  );
}
